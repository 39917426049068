import {PopperPlacementType} from '@material-ui/core';
import {isFunction} from 'lodash';
import React, {
  cloneElement,
  Fragment,
  useCallback,
  useRef,
  useState,
} from 'react';
import {PortalPopper} from './PortalPopper';


export {PortalPopper} from './PortalPopper';

export interface PortalDropdownProps {
  disabled?: boolean
  placement?: PopperPlacementType
  anchorElement: React.ReactElement | Function
  matchAnchorWidth?: boolean
  onOpen?: () => void
  onClose?: () => void
  children?: React.ReactNode | Function
}

const AddExtraProps = (Component, extraProps) => {
  return cloneElement(
    Component,
    extraProps
  );
};

export function PortalDropdown({
  disabled = false,
  placement = 'bottom',
  matchAnchorWidth,
  anchorElement,
  onOpen,
  onClose,
  children,
}: PortalDropdownProps) {
  const {
    anchorRef,
    open,
    handleToggle,
    handleClose,
  } = usePortalDropdown({disabled, onClose, onOpen});

  return (
    <Fragment>
      {AddExtraProps(isFunction(anchorElement) ? anchorElement({open, anchorRef}) : anchorElement, {
        ref: anchorRef,
        onClick: handleToggle,
      })}
      <PortalPopper
        open={open}
        placement={placement}
        matchAnchorWidth={matchAnchorWidth}
        anchorEl={anchorRef.current}
        onClose={handleClose}
      >
        {children}
      </PortalPopper>
    </Fragment>
  );
}

export interface UsePortalDropdownProps {
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export function usePortalDropdown({
  disabled,
  onOpen,
  onClose,
}: UsePortalDropdownProps) {
  const anchorRef = useRef<any>();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (!disabled) {
      setOpen(prev => {
        if (!prev) {
          onOpen && onOpen();
        }
        return true;
      });
    }
  }, [disabled, onOpen]);

  const handleToggle = useCallback(
    (event) => {
      if (!disabled) {
        if (event?.stopPropagation) {
          event.stopPropagation();
        }
        setOpen(prev => {
          const next = !prev;
          if (next) {
            onOpen && onOpen();
          } else {
            onClose && onClose();
          }
          return next;
        });
      }
    },
    [disabled, onOpen, onClose],
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      if (event?.stopPropagation) {
        event.stopPropagation();
      }

      const current = anchorRef.current;
      if (event && current && "contains" in current && current.contains && current.contains(event.target as HTMLElement)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  return {
    open,
    anchorRef,
    handleToggle,
    handleOpen,
    handleClose,
  };
}
