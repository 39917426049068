import {
  ResetAction,
  resetReducer,
} from '@app-lib/components/pages';
import {
  initialState as formsInitialState,
  isFormsAction,
  MainLayoutFormsActions,
  MainLayoutFormsState,
  reducer as formsReducer,
} from './forms';


export type MainLayoutAction =
  | MainLayoutFormsActions
  | ResetAction;

export interface MainLayoutState {
  form: MainLayoutFormsState,
}

export type MainLayoutDispatch = (action: MainLayoutAction) => void;

export type MainLayoutHook = [MainLayoutState, MainLayoutDispatch];

export const initialState: MainLayoutState = {
  form: formsInitialState,
};

export function reducer(state: MainLayoutState, action: MainLayoutAction): MainLayoutState {
  if (isFormsAction(action)) {
    return {
      ...state,
      form: formsReducer(state.form, action),
    };
  }

  return resetReducer(state, action, initialState);
}
