import {Theme} from '@material-ui/core/styles';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {ListItemMode} from '../../enums/ListItemMode';
import {
  ListItemMouseEvent,
  SelectorChangeEvent,
} from '../lists';


export type ResetAction = { type: "RESET" };

export type InfoPanelViewActions<T> =
  | { type: "SHOW_INFO_PANEL_BY_DEFAULT", payload: { show: boolean } }
  | { type: "SHOW_INFO_PANEL", payload: ListItemMouseEvent<T> }
  | { type: "HIDE_INFO_PANEL" }
  | { type: "RESET_INFO_PANEL" }

export type SetMultiSelectPayload = { active: boolean };

export type MultiModeViewActions =
  | { type: "SET_DISPLAY_MODE", payload: SelectorChangeEvent<ListItemMode> }
  | { type: "SET_MULTI_SELECT", payload: SetMultiSelectPayload }

export interface MultiModeViewState {
  displayMode: ListItemMode,
  multiSelect: boolean,
}

export interface InfoPanelViewState<T> {
  showInfoPanel: boolean,
  showInfoTarget?: T | null,
}

export interface DefaultInfoPanelViewState<T> extends InfoPanelViewState<T> {
  /** Whether to show the default info panel upon navigation. Based on current screen width. */
  showInfoByDefault: boolean,
  showInfoDefaultModel: boolean,
}

export function isInfoPanelViewAction<T>(action: { type: string }): action is InfoPanelViewActions<T> {
  switch (action.type) {
    case 'SHOW_INFO_PANEL_BY_DEFAULT':
    case 'SHOW_INFO_PANEL':
    case 'HIDE_INFO_PANEL':
    case 'RESET_INFO_PANEL':
      return true;
    default:
      return false;
  }
}

export function isMultiModeViewAction(action: { type: string }): action is MultiModeViewActions {
  switch (action.type) {
    case 'SET_DISPLAY_MODE':
    case 'SET_MULTI_SELECT':
      return true;
    default:
      return false;
  }
}

// Default reducer implementations.

export function resetReducer<S, A extends ResetAction | { type: string }>(state: S, action: A, initialState: S) {
  return action.type === 'RESET' ? initialState : state;
}

export function infoPanelViewReducer<M, S extends InfoPanelViewState<M>>(state: S, action: InfoPanelViewActions<M>): S {
  switch (action.type) {
    case 'SHOW_INFO_PANEL_BY_DEFAULT':
      return state;
    case 'SHOW_INFO_PANEL': {
      if (action.payload.model) {
        return {
          ...state,
          showInfoPanel: true,
          showInfoTarget: action.payload.model,
        };
      }
      return state;
    }
    case 'RESET_INFO_PANEL':
    case 'HIDE_INFO_PANEL':
      return {
        ...state,
        showInfoPanel: false,
        showInfoTarget: null,
      };
  }
}

export function defaultInfoPanelViewReducer<M, S extends DefaultInfoPanelViewState<M>>(
  state: S,
  action: InfoPanelViewActions<M>,
): S {
  switch (action.type) {
    case 'SHOW_INFO_PANEL_BY_DEFAULT':
      return {
        ...state,
        showInfoByDefault: action.payload.show,
      };
    case 'SHOW_INFO_PANEL': {
      if (action.payload.model) {
        return {
          ...state,
          showInfoPanel: true,
          showInfoTarget: action.payload.model,
          showInfoDefaultModel: false,
        };
      } else {
        return {
          ...state,
          showInfoPanel: true,
          showInfoTarget: null,
          showInfoDefaultModel: true,
        };
      }
    }
    case 'HIDE_INFO_PANEL':
      return {
        ...state,
        showInfoPanel: false,
        showInfoTarget: null,
        showInfoDefaultModel: true,
      };
    case 'RESET_INFO_PANEL':
      return {
        ...state,
        showInfoPanel: state.showInfoByDefault,
        showInfoTarget: null,
        showInfoDefaultModel: true,
      };
  }
}

export const InfoClosedBreakpoint: Breakpoint = 'md';

interface InitInfoClosedOnMobileArg<T> {
  initialState: T,
  theme: Theme,
}

export function initInfoClosedOnMobile<T>({initialState, theme}: InitInfoClosedOnMobileArg<T>): T {
  if (typeof window === 'undefined') {
    return initialState;
  }
  const width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
  const show = width >= theme.breakpoints.values[InfoClosedBreakpoint];
  return {
    ...initialState,
    showInfoByDefault: show,
    showInfoPanel: show,
  };
}

export function multiModeViewReducer<S extends MultiModeViewState>(state: S, action: MultiModeViewActions): S {
  switch (action.type) {
    case 'SET_DISPLAY_MODE':
      return {
        ...state,
        displayMode: action.payload.value,
        multiSelect: false,
      };
    case 'SET_MULTI_SELECT': {
      if (state.displayMode === ListItemMode.Grid) {
        return {
          ...state,
          multiSelect: action.payload.active,
        };
      }
      return state;
    }
  }
}
