import {
  ApolloError,
  gql,
} from '@apollo/client';
import {
  CurrentTenantFragment,
  CurrentTenantFragmentData,
  OrganizationFragment,
  OrganizationFragmentData,
} from '@app-features/organizations/types';
import {
  UserFragment,
  UserFragmentData,
} from '@app-features/users/types';
import {Connection} from '@app-lib/apollo/apiTypeGenerics';
import {useAuthQuery} from '@app-lib/apollo/useAuthQuery';
import {useCachedQuery} from '@app-lib/apollo/useCachedQuery';
import {useActionChannelPolling} from '@app-lib/apollo/useVisibilityPolling';
import {AppActions} from '@app-lib/redux/actions';
import {storeUser} from '@app-system/auth/redux/actions';
import {
  accessedTenant,
  hasAccessedTenant,
} from '@app-system/recent-access/redux/actions';
import {
  sortBy,
  take,
  unionBy,
} from 'lodash';
import {
  useEffect,
  useMemo,
} from 'react';
import {useDispatch} from 'react-redux';


export interface UseUserSessionHook {
  user?: UserFragmentData | null;
  tenant?: CurrentTenantFragmentData | null;
  tenants?: Array<OrganizationFragmentData>;
  hasMoreTenants?: boolean;
  loading?: boolean;
  error?: ApolloError | undefined;
}

const session = gql`
  query session {
    me {
      ...UserFragment
    }
    currentTenant {
      ...CurrentTenant
    }
    organizations(first: 4, searchText: null) {
      edges {
        node {
          ...OrganizationFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    recentTenants {
      ...OrganizationFragment
    }
  }
  ${UserFragment}
  ${CurrentTenantFragment}
  ${OrganizationFragment}
`;

export interface SessionDocumentNode {
  me?: UserFragmentData;
  currentTenant?: CurrentTenantFragmentData;
  organizations?: Connection<OrganizationFragmentData>;
  recentTenants?: Array<OrganizationFragmentData>;
}

// don't duplicate generic error messages.
const withError = async () => {};

export function useUserSession(): UseUserSessionHook {
  const dispatch = useDispatch();
  const result = useAuthQuery<SessionDocumentNode, any>(session, {});
  const {refetch} = result;
  const {data, error, loading} = useCachedQuery(result, {withError});

  useActionChannelPolling({actionTriggers, refetch});

  const tenants = useMemo(
    () => getTenants(data),
    [data],
  );

  useEffect(() => {
    if (data?.me?.id) {
      dispatch(storeUser(data?.me));
    }
  }, [dispatch, data?.me?.id]);

  useEffect(() => {
    if (data?.currentTenant?.id) {
      dispatch(accessedTenant({id: data?.currentTenant?.id}));
    }
  }, [dispatch, data?.currentTenant?.id]);

  if (data) {
    return {
      user: data.me,
      tenant: data.currentTenant,
      tenants,
      hasMoreTenants: !!data?.organizations?.pageInfo?.hasNextPage,
      loading,
      error,
    };
  } else {
    return {
      user: null,
      tenant: null,
      tenants: [],
      hasMoreTenants: false,
      loading,
      error,
    };
  }
}

function getTenants(data?: SessionDocumentNode): Array<OrganizationFragmentData> {
  const currentTenantId = data?.currentTenant?.id;
  if (data?.recentTenants?.length && data?.organizations?.edges?.length) {
    const all = data.organizations.edges.map(({node}) => node);
    const tenants = unionBy(data.recentTenants, all, it => it.id)
      .filter(it => it.id !== currentTenantId);
    // limit to 3 items in the menu
    const limited = take(tenants, 3);
    // @ts-ignore
    if (''.localeCompare) {
      return limited.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return sortBy(limited, ['name']);
    }
  } else if (data?.recentTenants?.length) {
    const tenants = data.recentTenants
      .filter(it => it.id !== currentTenantId);
    // limit to 3 items in the menu
    return take(tenants, 3);
  }
  const tenants = (data?.organizations?.edges ?? [])
    .map(({node}) => node)
    .filter(it => it.id !== currentTenantId);
  // limit to 3 items in the menu
  return take(tenants, 3);
}

function actionTriggers(action: AppActions): boolean {
  switch (action.type) {
    case hasAccessedTenant.type:
      // @ts-ignore - meta not supported in types yet
      return !action.meta.local;
  }
  return false;
}
