import {FileUpload} from '@app-lib/apollo/localTypes';
import {getListId} from '@app-lib/collections/utils';
import {ListItemMouseEvent} from '@app-lib/components/lists';
import {
  MonitorStats,
  QueueMeta,
} from '@forks/swmq';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import classNames from 'clsx';
import React, {Fragment} from 'react';
import {FileUploadHeader} from '../FileUploadHeader';
import {FileUploadItem} from '../FileUploadItem';


export interface FileUploadListProps {
  open?: boolean;
  meta: QueueMeta | null;
  list: Array<FileUpload>;
  stats: MonitorStats;
  onRemoveCompleted?: (e: React.MouseEvent<any>) => void;
  onRetryUpload?: (e: ListItemMouseEvent<FileUpload>) => void;
  onRemoveFile?: (e: ListItemMouseEvent<FileUpload>) => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return ({
    root: {},
    appIcon: {
      flexShrink: 0,
    },
    listSubHeader: {
      width: '100%',
      textAlign: 'right',
      padding: 0,
      background: theme.palette.primary.contrastText,
    },
    appBar: {},
    hide: {
      display: 'none',
    },
    drawerPaper: {
      width: '540px',
      bottom: 5,
      right: 5,
      border: '1px solid #3f51b5',
      left: 'auto',
      position: 'absolute',
      [theme.breakpoints.down('xs')]: {
        bottom: 0,
        width: '96%',
        maxHeight: '60%',
      },
    },
    uploadList: {
      paddingTop: 0,
      paddingBottom: '8px',
      maxHeight: '350px',
      overflowY: 'auto',
      zIndex: 99,
    },
    panel: {
      zIndex: theme.zIndex.drawer,
    },
  });
});

export function FileUploadList({
  open,
  meta,
  list,
  stats,
  onRemoveFile,
  onRetryUpload,
  onRemoveCompleted,
  onClose,
}: FileUploadListProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const hasItems = list.length > 0;
  return (
    <ListWrapper
      classes={classes}
      isMobile={isMobile}
      open={open}
      onClose={onClose}
    >
      <AppBar position="sticky">
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Typography variant="h6" noWrap>
              {stats.inProgress > 0
                ? `Uploading ${stats.inProgress} Files${stats.inProgress === 1 ? '' : 's'}`
                : `Upload Manager`
              }
            </Typography>
          </Box>
          <IconButton
            color="inherit"
            edge="end"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List className={classes.uploadList}>
        {meta ? (
          <ListSubheader>
            <FileUploadHeader
              stats={stats}
              meta={meta}
            />
          </ListSubheader>
        ) : renderSubHeader({
          className: classes.listSubHeader,
          hasItems,
          onRemoveCompleted,
        })}

        {hasItems ? list.map((item: FileUpload) => (
          <FileUploadItem
            key={getListId(item)}
            onRemove={onRemoveFile}
            onRetryUpload={onRetryUpload}
            item={item}
          />
        )) : (
          <ListItem>
            <ListItemText primary="No files are in the upload queue" />
          </ListItem>
        )}
      </List>
    </ListWrapper>
  );
}

interface ListWrapperProps {
  classes: ReturnType<typeof useStyles>,
  isMobile: boolean;
  open?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

function ListWrapper({
  classes,
  isMobile,
  open,
  onClose,
  children,
}: ListWrapperProps) {
  return (
    <div className={classes.root}>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={onClose}
        >
          {children}
        </Drawer>
      ) : (
        <Paper className={classNames(classes.drawerPaper, classes.panel)} elevation={20}>
          {children}
        </Paper>
      )}
    </div>
  );
}

function renderSubHeader({
  className,
  hasItems,
  onRemoveCompleted,
}) {
  return hasItems ? (
    <ListSubheader className={className}>
      <Typography variant="subtitle2" display="inline">
        Remove all complete items
      </Typography>
      <IconButton onClick={onRemoveCompleted}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <Divider />
    </ListSubheader>
  ) : null;
}
