import {ClickAwayListener} from '@app-components/ClickAwayListener';
import {DashboardIcon} from '@app-components/icons/DashboardIcon';
import {ProjectIcon} from '@app-components/icons/ProjectIcon';
import {MainLayoutForms} from '@app-components/layouts/MainLayout/MainLayoutForms';
import {showUploadManager} from '@app-features/file-tree/redux/upload-manager/actions';
import {NotificationButton} from '@app-features/notifications/components/NotificationButton';
import {UserItem} from '@app-features/users/components/UserItem';
import {UserMenu} from '@app-features/users/components/UserMenu';
import {FileUploadManager} from '@app-system/upload-manager/components/FileUploadManager';
import {
  AppBar,
  Badge,
  Drawer,
  Grow,
  Hidden,
  IconButton,
  InputBase,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  fade,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  Book as BookIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  DeleteForever as DeleteForeverIcon,
  Group as GroupIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import classNames from 'clsx';
import Link from 'next/link';
import React, {
  useCallback,
  useContext,
  useReducer,
  useRef,
  useState,
} from 'react';
import {useDispatch} from 'react-redux';
import {
  MainLayoutContainer,
  MainLayoutContext,
} from './container';
import {
  initialState,
  MainLayoutHook,
  reducer,
} from './state';


const drawerWidth = 240;
const drawerWidthMD = 200;

// @ts-ignore - typescript doesn't like the theme.mixins.toolbar
const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiBreadcrumbs-root': {
      color: theme.palette.primary.contrastText,
    },
  },
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthMD,
    },
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
    },
  },
  appBar: {
    background: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidthMD}px)`,
      marginLeft: drawerWidthMD,
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
  },
  logoContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: drawerWidthMD - 32,
    lineHeight: 1,
    [theme.breakpoints.up('lg')]: {
      maxWidth: drawerWidth - 32,
    },
    '& img': {
      width: '100%',
    },
  },
  popper: {
    zIndex: theme.zIndex.modal + 1,
  },
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthMD,
    },
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
    },
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  content: {
    flexGrow: 1,
  },
  contentPadding: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 0),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
    color: theme.palette.primary.contrastText,
  },
  listIcon: {
    minWidth: 36,
    color: theme.palette.primary.contrastText,
  },
  activeListItem: {
    background: theme.palette.primary.dark,
  },
  listItem: {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.dark,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',

  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  rightSection: {
    display: 'flex',
  },
}));

export interface MainLayoutProps {
  // current route props
  none?: boolean;
  dashboard?: boolean;
  project?: boolean;
  jobs?: boolean;
  files?: boolean;
  recycleBin?: boolean;
  team?: boolean;
  user?: boolean;
  templateRepo?: boolean;

  // other props
  breadcrumb?: React.ReactNode;
  padding?: boolean;
  children?: React.ReactNode;
}

interface InternalProps extends MainLayoutContainer {
  activeView;
  classes: ReturnType<typeof useStyles>;
  theme: Theme;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  handleOpenSettings: () => void;
}

enum ActiveView {
  none,
  dashboard,
  project,
  jobs,
  files,
  recycleBin,
  team,
  user,
  templateRepo,
}

function getActiveView(props: MainLayoutProps): ActiveView | null {
  if (props.none) return null;
  if (props.dashboard) return ActiveView.dashboard;
  if (props.project) return ActiveView.project;
  if (props.jobs) return ActiveView.jobs;
  if (props.files) return ActiveView.files;
  if (props.recycleBin) return ActiveView.recycleBin;
  if (props.team) return ActiveView.team;
  if (props.user) return ActiveView.user;
  if (props.templateRepo) return ActiveView.templateRepo;

  console.warn(`MainLayout rendered without specifying route!`);
  return null;
}

export function MainLayout(props: MainLayoutProps) {
  const data = useContext(MainLayoutContext);
  const {
    breadcrumb,
    padding,
    children,
  } = props;
  const activeView = getActiveView(props);
  const classes = useStyles();
  const theme = useTheme();
  const [state, dispatch]: MainLayoutHook = useReducer(reducer, initialState);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = useCallback(() => setMobileOpen(open => !open), []);
  const handleOpenSettings = useCallback(() => dispatch({type: 'OPEN_APP_SETTINGS', payload: {}}), []);
  const handleCloseForm = useCallback(() => dispatch({type: 'CLOSE_MUTATION_FORM'}), []);

  const internalProps = {
    ...data,
    activeView,
    classes,
    theme,
    mobileOpen,
    handleDrawerToggle,
    handleOpenSettings,
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Badge
              color="error"
              badgeContent={internalProps.uploadStats.errors}
            >
              <MenuIcon />
            </Badge>
          </IconButton>
          {breadcrumb}
          <div className={classes.grow} />
          <div className={classes.rightSection}>
            <NotificationButton color="inherit" />
          </div>
          {/* TODO: render notifications and any other appropriate props */}
        </Toolbar>
      </AppBar>
      {renderDrawer(internalProps)}
      <main className={classNames(classes.content, {[classes.contentPadding]: padding})}>
        <div className={classes.toolbar} />
        {children}
      </main>
      <FileUploadManager />
      <MainLayoutForms formState={state.form} onClose={handleCloseForm} />
    </div>
  );
}

function renderDrawer(internalProps: InternalProps) {
  const anchorRef = useRef();
  const {classes, theme, mobileOpen, handleDrawerToggle} = internalProps;
  // TODO: style mobile layout so that close button is not on top of the app logo
  const drawer = renderDrawerContent(internalProps, anchorRef);
  return (
    <nav
      className={classes.drawer}
      aria-label="mailbox folders"
    >
      <Hidden
        smUp
        implementation="css"
      >
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton
            className={classes.closeMenuButton}
            onClick={handleDrawerToggle}
          >
            <CloseIcon />
          </IconButton>

          {drawer}
        </Drawer>
      </Hidden>
      <Hidden
        xsDown
        implementation="css"
      >
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar}>
            <div className={classes.logoContainer}>
              <img src="/images/logo.png" alt="Logo" />
            </div>
          </div>
          {drawer}
        </Drawer>
      </Hidden>
      {renderUserMenu(internalProps, anchorRef)}
    </nav>
  );
}

function renderDrawerContent(internalProps: InternalProps, anchorRef) {
  const dispatch = useDispatch();
  const handleShowUploadManager = useCallback(() => dispatch(showUploadManager()), [dispatch]);
  const {
    uploadStats,
    tenant,
    user,
    handleOpenUserMenu,
    activeView,
    classes,
  } = internalProps;
  return (
    <div>
      <List>
        <UserItem
          user={user}
          onClick={handleOpenUserMenu}
          itemRef={anchorRef}
        />
        <ListItem>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{'aria-label': 'search'}}
            />
          </div>
        </ListItem>
      </List>

      <List>
        <SideMenuItem
          active={activeView === ActiveView.dashboard}
          href="/dashboard"
          label="Dashboard"
          icon={<DashboardIcon />}
        />
        <SideMenuItem
          active={activeView === ActiveView.project}
          href="/project"
          label="Projects"
          icon={<ProjectIcon />}
        />
        {/*
        // TODO: complete jobs page
        <SideMenuItem
          active={activeView === ActiveView.jobs}
          href="/jobs"
          label="Jobs"
          icon={<JobIcon />}
        />
        */}
        <SideMenuItem
          active={activeView === ActiveView.files}
          href="/folder"
          label="Files"
          icon={<InsertDriveFileIcon />}
          disabled={!tenant}
        />
        <SideMenuItem
          active={activeView === ActiveView.recycleBin}
          href="/recycle-bin"
          label="Recycle Bin"
          icon={<DeleteForeverIcon />}
        />
        <ListItem
          className={classes.listItem}
          button
          onClick={handleShowUploadManager}
        >
          <ListItemIcon className={classes.listIcon}>
            <Badge
              color="error"
              badgeContent={uploadStats.errors}
            >
              <CloudUploadIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText
            primary={<Typography noWrap>Upload Manager</Typography>}
          />
        </ListItem>
        {tenant?.hasPermissions?.team_data_read ? (
          <SideMenuItem
            active={activeView === ActiveView.team}
            href="/team"
            label="Teams"
            icon={<GroupIcon />}
          />
        ) : null}
        {tenant?.hasPermissions?.organization_members_read ? (
          <SideMenuItem
            active={activeView === ActiveView.user}
            href="/user"
            label="Users"
            icon={<GroupIcon />}
          />
        ) : null}
        {tenant?.hasPermissions?.template_data_read ? (
          <SideMenuItem
            active={activeView === ActiveView.templateRepo}
            href="/templates"
            label="Templates"
            icon={<BookIcon />}
          />
        ) : null}
      </List>
    </div>
  );
}

const popperOptions = {
  preventOverflow: true,
  boundariesElement: 'window',
};

function renderUserMenu(internalProps: InternalProps, anchorRef) {
  const {
    classes,
    tenant,
    tenants,
    hasMoreTenants,
    userMenu,
    showUserMenu,
    handleCloseUserMenu,
    handleOpenSettings,
  } = internalProps;
  if (!userMenu || !handleCloseUserMenu || !tenants) {
    return null;
  }
  return (
    <Popper
      className={classes.popper}
      open={!!showUserMenu}
      anchorEl={anchorRef.current}
      transition
      placement="right-start"
      popperOptions={popperOptions}
    >
      {({TransitionProps}) => (
        <Grow {...TransitionProps}>
          <ClickAwayListener onClickAway={handleCloseUserMenu}>
            {(caProps) => (
              <UserMenu
                {...userMenu}
                {...caProps}
                currentTenant={tenant}
                tenants={tenants}
                hasMoreTenants={hasMoreTenants}
                onOpenSettings={handleOpenSettings}
                onClose={handleCloseUserMenu}
              />
            )}
          </ClickAwayListener>
        </Grow>
      )}
    </Popper>
  );
}

interface SideMenuItemProps {
  active: boolean;
  href: string;
  label: string;
  icon?: any;
  disabled?: boolean;
}

function SideMenuItem({
  active,
  href,
  label,
  icon,
  disabled = false,
}: SideMenuItemProps) {
  const classes = useStyles();
  const listItem = (
    <ListItem
      className={classNames(classes.listItem, {
        [classes.activeListItem]: active,
      })}
      component={MuiLink}
      href={disabled ? undefined : href}
      disabled={disabled}
    >
      <ListItemIcon className={classes.listIcon}>
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography noWrap>{label}</Typography>
        }
      />
    </ListItem>
  );

  if (disabled) {
    return listItem;
  } else {
    return (
      <Link href={href}>
        {listItem}
      </Link>
    );
  }
}
