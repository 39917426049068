import React, {
  useCallback,
  useState,
} from 'react';


export const stopPropagation = e => e.stopPropagation();

/**
 * Use with onMouseDown to prevent the browser from triggering scroll compass.
 */
export const stopMiddleClickScroll = e => {
  if (e.button === 1) {
    return false;
  }
};

type Handler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

export function useHover(): [boolean, Handler, Handler] {
  const [value, setValue] = useState(false);

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => setValue(true),
    [setValue],
  );

  const handleMouseOut = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => setValue(false),
    [setValue],
  );

  return [value, handleMouseOver, handleMouseOut];
}
