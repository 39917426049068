import {ProgressButton} from '@app-components/ProgressButton';
import {
  clearComplete,
  clearFailed,
} from '@app-features/file-tree/redux/upload-manager/actions';
import {AppSettings} from '@app-features/settings/forms/AppSettings';
import {stopMiddleClickScroll} from '@app-lib/components/events';
import {
  popperOptions,
  useMenuPlacement,
} from '@app-system/context-menus/components/ContextMenu';
import {
  pauseUploadQueue,
  resumeUploadQueue,
  saveFilesFromUploadQueue,
  startUploadQueue,
} from '@app-system/upload-manager/redux/actions';
import {
  JobState,
  MonitorStats,
  QueueIndexes,
  QueueMeta,
} from '@forks/swmq';
import {
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
// HelpOutline as HelpOutlineIcon,
import {
  Delete as DeleteIcon,
  Done as DoneIcon,
  InsertDriveFile as InsertDriveFileIcon,
  MoreVert as MoreVertIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  Settings as SettingsIcon,
  Sync as SyncIcon,
} from '@material-ui/icons';
import classNames from 'clsx';
import React, {
  useCallback,
  useRef,
  useState,
} from 'react';
import {useDispatch} from 'react-redux';


export interface FileUploadHeaderProps {
  stats: MonitorStats;
  meta: QueueMeta;
}

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginLeft: 'auto',
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  itemIcon: {
    color: 'inherit',
  },
  dangerous: {
    color: theme.palette.error.main,
  },
}));

interface FileUploadHeaderState {
  showMenu: boolean;
  showSettings: boolean;
}

const initialState: FileUploadHeaderState = {
  showMenu: false,
  showSettings: false,
};

export function FileUploadHeader({
  stats,
  meta,
}: FileUploadHeaderProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [{showMenu, showSettings}, setState] = useState(initialState);
  const menuRef = useRef<HTMLButtonElement>(null);
  const placement = useMenuPlacement(showMenu && menuRef.current ? menuRef.current : false);

  const handleShowMenu = useCallback(() => setState(state => ({...state, showMenu: true})), []);
  const handleHideMenu = useCallback(() => setState(state => ({...state, showMenu: false})), []);

  const handleHideSettings = useCallback(() => setState(state => ({...state, showSettings: false})), []);

  const handleSync = useCallback(() => dispatch(startUploadQueue()), [dispatch]);
  const handlePause = useCallback(() => dispatch(pauseUploadQueue()), [dispatch]);
  const handleResume = useCallback(() => dispatch(resumeUploadQueue()), [dispatch]);

  return (
    <Grid container spacing={1}>
      <Grid item>
        <ProgressButton
          variant="contained"
          color="primary"
          disabled={!stats.total}
          loading={meta.processing}
          startIcon={<SyncIcon />}
          onClick={handleSync}
        >
          Sync
        </ProgressButton>
      </Grid>
      <Grid item>
        {meta.paused ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<PlayCircleOutlineIcon />}
            onClick={handleResume}
          >
            Resume
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<PauseCircleOutlineIcon />}
            onClick={handlePause}
          >
            Pause
          </Button>
        )}
      </Grid>
      <Grid item className={classes.menuButton}>
        <IconButton ref={menuRef} onClick={handleShowMenu}>
          <MoreVertIcon />
        </IconButton>
      </Grid>

      {showSettings ? (
        <AppSettings
          onClose={handleHideSettings}
          onSuccess={handleHideSettings}
        />
      ) : null}

      <Popper
        className={classes.popper}
        open={showMenu}
        anchorEl={menuRef.current}
        transition
        placement={placement}
        popperOptions={popperOptions}
        onMouseDown={stopMiddleClickScroll}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={12}>
              <ClickAwayListener onClickAway={handleHideMenu}>
                <MenuList>
                  {renderMenuItem({
                    classes,
                    Icon: InsertDriveFileIcon,
                    label: 'Save Failed Uploads As...',
                    disabled: !stats.errors,
                    onClick: () => {
                      dispatch(saveFilesFromUploadQueue({
                        options: {
                          property: QueueIndexes.status,
                          status: JobState.FailedPermanently,
                        },
                      }));
                      handleHideMenu();
                    },
                  })}
                  {renderMenuItem({
                    classes,
                    Icon: InsertDriveFileIcon,
                    label: 'Save Pending Uploads As...',
                    disabled: !stats.pending,
                    onClick: () => {
                      dispatch(saveFilesFromUploadQueue({
                        options: {
                          property: QueueIndexes.status,
                          status: JobState.Pending,
                        },
                      }));
                      handleHideMenu();
                    },
                  })}
                  {renderMenuItem({
                    classes,
                    Icon: InsertDriveFileIcon,
                    label: 'Save All Uploads As...',
                    disabled: !stats.total,
                    onClick: () => {
                      dispatch(saveFilesFromUploadQueue({}));
                      handleHideMenu();
                    },
                  })}
                  <Divider />
                  {/* renderMenuItem({
                    classes,
                    Icon: SettingsIcon,
                    label: 'Settings',
                    onClick: () => {
                      setState(state => ({
                        ...state,
                        showMenu: false,
                        showSettings: true,
                      }));
                    },
                  })}
                  {/* renderMenuItem({
                    classes,
                    Icon: HelpOutlineIcon,
                    label: 'Help',
                    onClick: () => {
                      // TODO: dispatch action to display relevant in-app help page
                      handleHideMenu();
                    },
                  }) */}
                  <Divider />
                  {renderMenuItem({
                    classes,
                    Icon: DoneIcon,
                    label: 'Clear Completed Uploads',
                    disabled: !stats.complete,
                    onClick: () => {
                      dispatch(clearComplete());
                      handleHideMenu();
                    },
                  })}
                  {renderMenuItem({
                    classes,
                    Icon: DeleteIcon,
                    label: 'Remove Failed Uploads',
                    disabled: !stats.errors,
                    dangerous: true,
                    onClick: () => {
                      dispatch(clearFailed());
                      handleHideMenu();
                    },
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Grid>
  );
}

interface RenderMenuItemProps {
  classes: ReturnType<typeof useStyles>;
  Icon: React.ComponentType<any>;
  label: React.ReactNode;
  dangerous?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

function renderMenuItem({
  classes,
  Icon,
  label,
  dangerous,
  disabled,
  onClick,
}: RenderMenuItemProps) {
  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <ListItemIcon
        className={classNames(classes.itemIcon, {
          [classes.dangerous]: dangerous,
        })}
      >
        <Icon />
      </ListItemIcon>
      <ListItemText
        primary={(
          <Typography noWrap>
            {label}
          </Typography>
        )}
      />
    </MenuItem>
  );
}
