import {InfoClosedBreakpoint} from '@app-lib/components/pages/state';
import {ListItemMode} from '@app-lib/enums/ListItemMode';
import {useMediaQuery} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  getListItemMouseEvent,
  SelectorChangeEvent,
} from '../lists';


export function useDisplayMode(dispatch, onSelectItem) {
  const handleChangeDisplayMode = useCallback((e: SelectorChangeEvent<ListItemMode>) => {
    dispatch({type: 'SET_DISPLAY_MODE', payload: e});
  }, []);

  const handleChangeMultiSelect = useCallback((e, checked) => {
    dispatch({type: 'SET_MULTI_SELECT', payload: {active: checked}});
    if (!checked) {
      onSelectItem('RESET');
    }
  }, [onSelectItem]);

  return {
    handleChangeDisplayMode,
    handleChangeMultiSelect,
  };
}

export function useInfoPanel(theme, dispatch) {
  // Default to desktop behavior in SSR
  const isNotMobile = typeof window !== 'undefined' ? useMediaQuery(theme.breakpoints.up(InfoClosedBreakpoint)) : true;

  const toggleDrawer = useCallback((event) => {
    // Event implementation copied from Drawer docs.
    // TODO: finalize and externalize this condition for info panels.
    // Panels need to able to scroll so we can't auto close them on tap
    // This may need to be configurable?
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch({type: 'HIDE_INFO_PANEL'});
  }, []);

  const handleClickItem = useCallback((e) => {
    dispatch({type: 'SHOW_INFO_PANEL', payload: e});
  }, []);

  const handleCloseInfo = useCallback(() => {
    dispatch({type: 'HIDE_INFO_PANEL'});
  }, []);

  return {
    isNotMobile,
    toggleDrawer,
    handleClickItem,
    handleCloseInfo,
  };
}

export function useDefaultInfoPanel<T>(theme: Theme, dispatch, resetProp?: any) {
  const baseHooks = useInfoPanel(theme, dispatch);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    dispatch({type: 'SHOW_INFO_PANEL_BY_DEFAULT', payload: {show: baseHooks.isNotMobile}});
  }, [baseHooks.isNotMobile]);

  const handleShowInfo = useCallback((e) => {
    dispatch({type: 'SHOW_INFO_PANEL', payload: getListItemMouseEvent<T | null>(e, null)});
  }, []);

  const handleResetInfo = useCallback(() => {
    dispatch({type: 'RESET_INFO_PANEL'});
  }, []);

  useEffect(() => {
    if (!firstUpdate.current) {
      handleResetInfo();
    }
  }, [resetProp]);

  return {
    ...baseHooks,
    firstUpdate,
    handleShowInfo,
    handleResetInfo,
  };
}
