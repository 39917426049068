import {gql} from '@apollo/client';
import {
  OrganizationFragment,
  OrganizationFragmentData,
} from '@app-features/organizations/types';
import {Connection} from '@app-lib/apollo/apiTypeGenerics';


export const organizations = gql`
  query organizations(
    $first: Int!
    $after: String
    $searchText: String
  ) {
    organizations(
      searchText: $searchText
      first: $first
      after: $after
    ) {
      edges {
        node {
          ...OrganizationFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${OrganizationFragment}
`;

export interface OrganizationsDocumentNode {
  organizations?: Connection<OrganizationFragmentData>;
}
