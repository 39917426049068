import {SettingsProps} from '@app-features/settings/components/Settings/index';
import {
  setCameraOptions,
  setTheme,
  setTimezone,
} from '@app-system/preferences/redux/actions';
import {Resolutions} from '@app-system/preferences/redux/reducer';
import {getPreferences} from '@app-system/preferences/redux/state';
import {findIndex} from 'lodash';
import moment from 'moment-timezone';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';


export function useClientSettings(): SettingsProps {
  const dispatch = useDispatch();
  const preferences = useSelector(getPreferences);
  const [cameraPermission, setCameraPermission] = useState<PermissionState | 'error'>('error');
  const [locationPermission, setLocationPermission] = useState<PermissionState | 'error'>('error');

  useEffect(() => {
    let unmounted = false;
    let cameraStatus: PermissionStatus;
    let locationStatus: PermissionStatus;
    const cameraListener = function listener() {
      setCameraPermission(this.state);
    };
    const locationListener = function listener() {
      setLocationPermission(this.state);
    };

    if ('permissions' in navigator) {
      navigator.permissions.query({name: 'camera'})
        .then((result) => {
          if (!unmounted) {
            cameraStatus = result;
            setCameraPermission(result.state);
            cameraStatus.addEventListener('change', cameraListener);
          }
        })
        .catch(e => {
          if (!unmounted) {
            setCameraPermission('error');
          }
        });
      navigator.permissions.query({name: 'geolocation'})
        .then((result) => {
          if (!unmounted) {
            locationStatus = result;
            setLocationPermission(result.state);
            locationStatus.addEventListener('change', cameraListener);
          }
        })
        .catch(e => {
          if (!unmounted) {
            setCameraPermission('error');
          }
        });
    }

    return () => {
      unmounted = true;
      if (cameraStatus) {
        cameraStatus.removeEventListener('change', cameraListener);
      }
      if (locationStatus) {
        locationStatus.removeEventListener('change', locationListener);
      }
    };
  });

  const cameraResolution = preferences.cameraResolution
    ? findIndex(Resolutions, ({width}) => width === preferences.cameraResolution.width)
    : 1;
  const handleUpdateCameraResolution = useCallback(({value}) => {
    if (Resolutions[value]) {
      dispatch(setCameraOptions({
        cameraResolution: Resolutions[value],
      }));
    }
  }, [dispatch]);

  const handleUseCameraAccept = useCallback(({value}) => {
    dispatch(setCameraOptions({
      useCameraAccept: value,
    }));
  }, [dispatch]);

  const handleUseCurrentTimezone = useCallback(({value}) => {
    if (value) {
      dispatch(setTimezone({
        timezone: moment.tz.guess(),
        lockTimezone: false,
      }));
    } else {
      dispatch(setTimezone({
        lockTimezone: true,
      }));
    }
  }, [dispatch]);

  const handleUpdateTimezone = useCallback(({value}) => {
    dispatch(setTimezone({
      timezone: value,
      lockTimezone: true,
    }));
  }, [dispatch]);

  const handleUpdateTheme = useCallback(({value}) => {
    dispatch(setTheme({theme: value}));
  }, [dispatch]);

  return {
    cameraPermission,
    locationPermission,
    cameraResolution,
    useCameraAccept: preferences.useCameraAccept,
    dateFormat: preferences.dateFormat,
    timezone: preferences.timezone,
    useCurrentTimezone: !preferences.lockTimezone,
    theme: preferences.theme,
    onChangeCameraResolution: handleUpdateCameraResolution,
    onChangeUseCameraAccept: handleUseCameraAccept,
    onChangeUseCurrentTimezone: handleUseCurrentTimezone,
    onChangeTimezone: handleUpdateTimezone,
    onChangeTheme: handleUpdateTheme,
  };
}
