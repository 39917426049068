import {
  ApolloError,
  gql,
  useLazyQuery,
} from '@apollo/client';
import {Connection} from '@app-lib/apollo/apiTypeGenerics';
import {PageInfo} from '@app-lib/apollo/apiTypes';
import {LocalUserNotification} from '@app-lib/apollo/localTypes';
import {
  buildInfiniteScrollUpdateQuery,
  RelayListProps,
} from '@app-lib/components/lists';
import {useDateTimeFormat} from '@app-system/time';
import {get} from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';


const USER_NOTIFICATIONS = gql`
  query userNotifications(
    $after: String
    $dateFormat: DateFormatInput
  ) {
    myNotifications(
      after: $after
    ) {
      edges {
        node {
          __typename
          id
          time(dateFormat: $dateFormat)
          message
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export interface UseNotificationsHook {
  notifications?: RelayListProps<LocalUserNotification>,
  showNotifications?: boolean,
  handleOpenNotifications?: (e: React.MouseEvent<any>) => void,
  handleCloseNotifications?: (e: React.MouseEvent<any>) => void,
  error?: ApolloError | null,
}

export function getNotifications(data): Connection<LocalUserNotification> {
  const connection = get(data, 'myNotifications', {});
  if (!connection) {
    return {
      edges: [],
      pageInfo: {
        hasNextPage: false,
      } as PageInfo,
    };
  } else {
    return connection;
  }
}

const updateQuery = buildInfiniteScrollUpdateQuery({
  connectionPath: 'userNotifications',
});

export function useNotifications(): UseNotificationsHook {
  const dateFormat = useDateTimeFormat();
  const [loadNotifications, {data, loading, error, fetchMore}] = useLazyQuery(USER_NOTIFICATIONS, {
    ssr: false,
  });
  const cursor = get(data, 'folderById.children.pageInfo.endCursor');

  const loadMoreItems = useCallback(() => {
    return fetchMore && fetchMore({
      variables: {
        after: cursor,
        dateFormat,
      },
      updateQuery,
    }).then(() => undefined);
  }, [fetchMore, cursor]);

  const [isOpen, setOpen] = useState(false);

  const handleOpenNotifications = useCallback(() => setOpen(true), [setOpen]);
  const handleCloseNotifications = useCallback(() => setOpen(false), [setOpen]);

  // TODO: should probably convert this to rxjs...
  useEffect(
    () => {
      if (isOpen && loadNotifications) {
        loadNotifications({
          variables: {
            dateFormat,
          },
        });
      }
    },
    [isOpen, loadNotifications],
  );

  if (data) {
    const list = getNotifications(data);
    return {
      showNotifications: isOpen,
      handleOpenNotifications,
      handleCloseNotifications,
      notifications: {
        loading,
        list,
        hasMoreItems: list.pageInfo.hasNextPage,
        loadMoreItems,
      },
    };
  } else {
    return {
      showNotifications: isOpen,
      handleOpenNotifications,
      handleCloseNotifications,
      notifications: {
        loading,
        loadMoreItems,
      },
      error,
    };
  }
}
