import {
  ClickableItemProps,
  getListItemMouseEvent,
  ListItemMouseEvent,
  MultiSelectableItemProps,
} from '@app-lib/components/lists';
import {ListItemProps} from '@material-ui/core/ListItem/ListItem';
import {ListItem} from '@material-ui/core';
import React, {
  Component,
  CSSProperties,
} from 'react';


// partially extends ListItemProps but overwrites props found in ClickableItemProps
export interface ClickableListItemProps<T, E = T> extends ClickableItemProps<E>,
  MultiSelectableItemProps<T> {
  className?: string
  style?: CSSProperties
  as?: React.ComponentType<any>
  requireModel?: boolean
  model?: T | null
  button?: boolean
  alignItems?: ListItemProps["alignItems"]
}

/**
 * Standardized click event handler for list/grid contents.
 *
 * In useSelect mode, click actions are replaced by selection actions.
 * For Grid displays, only pass useSelect true when user has activated multi-select mode.
 * Do not use select mode for ListItem. Use a checkbox instead.
 */
export class ClickableListItem<T, E = T> extends Component<ClickableListItemProps<T, E>, any> {
  private clickCount: number;
  private clickEvent: ListItemMouseEvent<E> | null;
  private timeout: NodeJS.Timeout | null;

  constructor(props) {
    super(props);

    this.clickCount = 0;
    this.timeout = null;
    this.clickEvent = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleAuxClick = this.handleAuxClick.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
  }

  private onSingleClick(e: ListItemMouseEvent<E>) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  private onDoubleClick(e: ListItemMouseEvent<E>) {
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick(e);
    }
  }

  private handleSelect() {
    const {
      model,
      selected,
      onSelect,
    } = this.props;

    if (model && onSelect) {
      onSelect({model, selected: !selected});
    }
  }

  private isModelValid(model): model is E {
    return this.props.requireModel ? !!model : true;
  }

  private handleAuxClick(event) {
    if (!this.isModelValid(this.props.model)) {
      return;
    }
    // for some reason, react is firing this handler on mouse 2 and 3 so make sure we only get middle mouse
    if (event.button === 1 && this.props.onAuxClick) {
      event.preventDefault();
      event.stopPropagation();
      this.props.onAuxClick(getListItemMouseEvent(event, this.props.model));
    }
  }

  private handleContextMenu(event) {
    if (!this.isModelValid(this.props.model)) {
      return;
    }
    if (this.props.onContextMenu) {
      event.preventDefault();
      event.stopPropagation();
      this.props.onContextMenu(getListItemMouseEvent(event, this.props.model));
    }
  }

  private handleClick(e: React.MouseEvent<any>) {
    if (!this.isModelValid(this.props.model)) {
      return;
    }
    if (this.props.useSelect) {
      e.stopPropagation();
      this.handleSelect();
      return;
    }
    if (this.props.onClick || this.props.onDoubleClick) {
      e.stopPropagation();
    }
    // Don't delay click actions if the item isn't using dblclick.
    if (!this.props.onDoubleClick && this.props.onClick) {
      this.props.onClick(getListItemMouseEvent(e, this.props.model));
      return;
    }
    this.clickCount++;

    if (this.clickCount < 2) {
      this.clickEvent = getListItemMouseEvent(e, this.props.model);
      this.timeout = setTimeout(() => {
        if (this.clickCount === 1 && this.clickEvent) {
          this.onSingleClick(this.clickEvent);
        }
        this.timeout = null;
        this.clickCount = 0;
        this.clickEvent = null;
      }, 500);
    } else if (this.clickCount === 2) {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      if (this.clickEvent) {
        this.onDoubleClick(this.clickEvent);
      }
      this.clickCount = 0;
      this.clickEvent = null;
    }
  }

  componentWillUnmount(): void {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const {
      as: ItemComponent = ListItem,
      requireModel,
      model,
      onClick,
      onAuxClick,
      onDoubleClick,
      onContextMenu,
      useSelect,
      selected,
      onSelect,
      itemRef,
      ...itemProps
    } = this.props;
    return (
      // @ts-ignore
      <ItemComponent
        {...itemProps}
        ref={itemRef}
        onClick={this.handleClick}
        onContextMenu={this.handleContextMenu}
        onAuxClick={this.handleAuxClick}
      >
        {this.props.children}
      </ItemComponent>
    );
  }
}
