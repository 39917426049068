import {ApolloError} from '@apollo/client/errors';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {
  DependencyList,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useDispatch} from 'react-redux';


interface ResultInput<TData> {
  data: TData | undefined
  error?: ApolloError
  lastErrorMessage?: string
  loading: boolean
}

interface Result<TData> extends ResultInput<TData> {
  deps: DependencyList | undefined
}

interface UseCachedQueryOptions {
  withError?: (currentError: ApolloError, previousError: ApolloError | undefined) => Promise<void>
  resetDataOnChange?: DependencyList
}

function getDefaultResult<TData>(
  r: ResultInput<TData> | undefined,
  deps: DependencyList | undefined,
): Result<TData> {
  return r ? {
    data: r.data,
    error: r.error,
    loading: r.loading,
    deps,
  } : {
    data: void 0,
    error: void 0,
    loading: true,
    deps,
  };
}

export function useCachedQuery<TData, TVariables>(
  result: ResultInput<TData>,
  {withError, resetDataOnChange}: UseCachedQueryOptions = {},
) {
  const dispatch = useDispatch();
  const dataRef = useRef<Result<TData>>(getDefaultResult(result, resetDataOnChange));
  const {data, error, loading} = result;

  // use the same method as Apollo Client to force a rerender if data changes.
  const [_tick, setTick] = useState(0);

  useEffect(() => {
    const prevData = dataRef.current.data;
    const prevError = dataRef.current.error;
    const prevLoading = dataRef.current.loading;
    const prevDeps = dataRef.current.deps;
    dataRef.current.error = error;
    dataRef.current.loading = loading;
    dataRef.current.deps = resetDataOnChange;

    let hasChange = prevLoading !== loading;

    if (data) {
      dataRef.current.data = data;
      if (prevData && prevData !== data) {
        hasChange = true;
      }
    }

    if (prevError !== error) {
      hasChange = true;
      if (error) {
        console.error(`Query got error`, error);
        if (withError) {
          withError(error, prevError).catch(() => void 0);
        } else {
          const message = getErrorMessage(error);
          if (dataRef.current.lastErrorMessage !== message) {
            dataRef.current.lastErrorMessage = message;
            dispatch(enqueueNotification({
              message,
              options: {
                key: 'RefreshPageData',
                variant: 'warning',
              },
            }));
          }
        }
      }
    }

    if (resetDataOnChange && depsChanged(resetDataOnChange, prevDeps as DependencyList)) {
      dataRef.current.data = data;
      hasChange = true;
    }

    if (hasChange) {
      setTick(tick => tick + 1);
    }
  }, [data, error, loading, withError, ...(resetDataOnChange ?? [])]);

  return dataRef.current;
}

function getErrorMessage(error: ApolloError): string {
  return error.networkError
    ? 'Network error. Not loading new changes.'
    : 'Failed to load new data. Not loading new changes.';
}

function depsChanged(deps: DependencyList, prevDeps: DependencyList): boolean {
  for (let i = 0, len = deps.length; i < len; i++) {
    if (deps[i] !== prevDeps[i]) {
      return true;
    }
  }
  return false;
}
