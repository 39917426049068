import {
  Button,
  ButtonProps,
  CircularProgress,
  Theme,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import classNames from 'clsx';


export interface ProgressButtonProps extends ButtonProps {
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export function ProgressButton({
  className,
  loading,
  children,
  ...props
}: ProgressButtonProps) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      <Button
        {...props}
        disabled={loading || props.disabled}
      >
        {children}
      </Button>
      {loading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : null}
    </div>
  );
}
