import {gql} from '@apollo/client';
import {User} from '@app-lib/apollo/apiTypes';


export interface UserMembershipPermissions {
  organization_members_read?: boolean | null;
  organization_members_manage?: boolean | null;
}

export type UserFragmentData = Pick<User,
  | "__typename"
  | "id"
  | "name"
  | "email"
  | "firstName"
  | "lastName"
  | "avatar">

export const UserFragment = gql`
  fragment UserFragment on User {
    __typename
    id
    name
    email
    firstName
    lastName
    avatar
  }
`;
