/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function DashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="1024"
      height="1024"
      viewBox="0 0 1024 1024"
    >
      <path d="M955.733 546.134c37.703 0 68.266 30.564 68.266 68.266v273.067c0 37.703-30.564 68.266-68.266 68.266h-546.134c-37.703 0-68.266-30.564-68.266-68.266v-273.067c0-37.703 30.564-68.266 68.266-68.266h546.134zM204.8 546.134c37.703 0 68.266 30.564 68.266 68.266v273.067c0 37.703-30.564 68.266-68.266 68.266h-136.533c-37.703 0-68.266-30.564-68.266-68.266v-273.067c0-37.703 30.564-68.266 68.266-68.266h136.533zM614.4 68.267c37.703 0 68.266 30.564 68.266 68.266v273.067c0 37.703-30.564 68.266-68.266 68.266h-546.134c-37.703 0-68.266-30.564-68.266-68.266v-273.067c0-37.703 30.564-68.266 68.266-68.266h546.134zM955.733 68.267c37.703 0 68.266 30.564 68.266 68.266v273.067c0 37.703-30.564 68.266-68.266 68.266h-136.533c-37.703 0-68.266-30.564-68.266-68.266v-273.067c0-37.703 30.564-68.266 68.266-68.266h136.533z" />
    </SvgIcon>
  );
}
