import {ClickableListItem} from '@app-components/ClickableListItem';
import {FileIcon} from '@app-features/file-tree/components/FileIcon';
import {FileUpload} from '@app-lib/apollo/localTypes';
import {
  getListItemMouseEvent,
  ListItemMouseEvent,
} from '@app-lib/components/lists';
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {useCallback} from 'react';
import {SecondaryActionText} from '../SecondaryActionText';
import {SecondaryItemText} from '../SecondaryItemText';


export interface FileUploadItemProps {
  item: FileUpload;
  onRemove?: (e: ListItemMouseEvent<FileUpload>) => void;
  onRetryUpload?: (e: ListItemMouseEvent<FileUpload>) => void;
  onContextMenu?: (e: ListItemMouseEvent<FileUpload>) => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return ({
    root: {
      '& .MuiTypography-noWrap': {
        width: '80%',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '25px',
      },
    },
    fileIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  });
});

export function FileUploadItem({
  item,
  onRemove,
  onRetryUpload,
  onContextMenu,
}: FileUploadItemProps) {
  const classes = useStyles();

  const handleRemove = useCallback((e: React.MouseEvent<any>) => {
    e.stopPropagation();
    onRemove && onRemove(getListItemMouseEvent(e, item));
  }, [onRemove, item]);

  const handleRetryUpload = useCallback((e: React.MouseEvent<any>) => {
    e.stopPropagation();
    onRetryUpload && onRetryUpload(getListItemMouseEvent(e, item));
  }, [onRetryUpload, item]);

  return (
    <ClickableListItem
      className={classes.root}
      model={item}
      requireModel
      onContextMenu={onContextMenu}
    >
      <ListItemIcon>
        <FileIcon className={classes.fileIcon} name={item.name} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography noWrap>
            {item.name}
          </Typography>
        }
        secondary={(
          <SecondaryItemText
            item={item}
            onRemove={handleRemove}
          />
        )}
      />
      <ListItemSecondaryAction>
        <SecondaryActionText
          item={item}
          onRemove={handleRemove}
          onRetryUpload={handleRetryUpload}
        />
      </ListItemSecondaryAction>
    </ClickableListItem>
  );
}
