import {Dropdown} from '@app-components/filters/Dropdown';
import {getListId} from '@app-lib/collections/utils';
import {stopMiddleClickScroll} from '@app-lib/components/events';
import {
  getListItemMouseEvent,
  ListItemMouseEvent,
} from '@app-lib/components/lists';
import {
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {ArrowRight as ArrowRightIcon} from '@material-ui/icons';
import classNames from 'clsx';
import {ReferenceObject} from 'popper.js';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  ContextMenuContext,
  ContextMenuDivider,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuState,
} from '../../index';


export interface ContextMenuItemEvent<T, M, C extends ContextMenuContext> extends ListItemMouseEvent<ContextMenuItem<T>> {
  targets: Array<M>;
  context: C;
}

export interface ContextMenuProps<T, M, C extends ContextMenuContext> extends ContextMenuState<T, M, C> {
  getKey?: (model: M) => string,
  onClick: (e: ContextMenuItemEvent<T, M, C>) => void,
  onClose: () => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  itemIcon: {
    color: 'inherit',
  },
  dangerous: {
    color: theme.palette.error.main,
  },
}));

export const popperOptions = {
  preventOverflow: true,
  boundariesElement: 'window',
};

export function ContextMenu<T, M, C extends ContextMenuContext>({
  show,
  menuItems,
  singleTargetModel,
  singleTargetMenuItems,
  targets,
  context,
  // @ts-ignore - required if model does not strictly extend INode
  getKey = getListId,
  onClick,
  onClose,
}: ContextMenuProps<T, M, C>) {
  const classes = useStyles();
  const handleClick = useCallback(
    (menuItem) => (e) => {
      if (onClick) {
        const event = getListItemMouseEvent(e, menuItem) as Partial<ContextMenuItemEvent<T, M, C>>;
        event.targets = singleTargetModel ? [singleTargetModel] : targets;
        event.context = context;
        onClick(event as ContextMenuItemEvent<T, M, C>);
      }
    },
    [onClick, singleTargetModel, targets, context],
  );
  // Cache the previous items so that when we close the menu, we can still render the list.
  const prevStateRef = useRef<Pick<ContextMenuProps<T, M, C>, "show" | "menuItems">>();
  useEffect(() => {
    if (!prevStateRef.current) {
      prevStateRef.current = {
        show,
        menuItems: singleTargetMenuItems || menuItems,
      };
      return;
    }

    if (prevStateRef.current.show !== show) {
      if (show) {
        prevStateRef.current = {
          show,
          menuItems: singleTargetMenuItems || menuItems,
        };
      } else {
        prevStateRef.current = {
          show,
          menuItems: prevStateRef.current.menuItems,
        };
      }
    }
  });
  const baseId = `${targets.length}:${targets[0] ? getKey(targets[0]) : null}:`;
  const items = getCurrentItems(
    show,
    singleTargetMenuItems,
    menuItems,
    prevStateRef.current,
  );
  const hasItems = items.length > 0;
  const placement = useMenuPlacement(show);

  return (
    <Popper
      className={classes.popper}
      open={Boolean(show)}
      anchorEl={show || undefined}
      transition
      placement={placement}
      popperOptions={popperOptions}
      onMouseDown={stopMiddleClickScroll}
    >
      {({TransitionProps}) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={12}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList>
                {!hasItems ? (
                  <MenuItem disabled>
                    <ListItemText
                      primary={(
                        <Typography noWrap>
                          (No valid actions)
                        </Typography>
                      )}
                    />
                  </MenuItem>
                ) : null}
                {items.map((menuItem, i) =>
                  renderMenuItem<T>(classes, `${baseId}${i}`, menuItem, handleClick)
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

export function useMenuPlacement(show: false | ReferenceObject) {
  return useMemo(() => {
    if (!show || typeof window === 'undefined') {
      return 'bottom-start';
    } else {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;
      const rect = show.getBoundingClientRect();
      const vertical = rect.top < windowHeight / 2 ? 'bottom' : 'top';
      const horizontal = rect.left < windowWidth / 2 ? 'start' : 'end';
      return `${vertical}-${horizontal}` as PopperPlacementType;
    }
  }, [show]);
}

function getCurrentItems<T, M, C extends ContextMenuContext>(
  show: ContextMenuProps<T, M, C>["show"],
  singleTargetMenuItems: ContextMenuProps<T, M, C>["singleTargetMenuItems"],
  menuItems: ContextMenuProps<T, M, C>["menuItems"],
  prevState: Pick<ContextMenuProps<T, M, C>, "show" | "menuItems"> | undefined
): ContextMenuProps<T, M, C>["menuItems"] {
  if (show) {
    return singleTargetMenuItems || menuItems;
  } else {
    return prevState?.menuItems || menuItems;
  }
}

function renderMenuItem<T>(
  classes,
  key: string | number,
  menuItem: ContextMenuGroup<T> | ContextMenuItem<T> | ContextMenuDivider,
  handleClick,
) {
  if (menuItem === ContextMenuDivider) {
    return (
      <Divider key={key} />
    );
  } else if (menuItem.group) {
    return (
      <Dropdown
        key={key}
        placement="right-start"
        anchorElement={(
          <ListItem button>
            {renderItemBody(classes, menuItem)}
            <ArrowRightIcon />
          </ListItem>
        )}
      >
        {({handleClose}) => (
          <Box>
            {menuItem.items.map((childMenuItem, i) => renderMenuItem<T>(classes, i, childMenuItem, handleClick))}
          </Box>
        )}
      </Dropdown>
    );
  } else {
    return renderContextMenuItem(classes, key, menuItem, handleClick);
  }
}

function renderContextMenuItem<T>(
  classes,
  key: string | number,
  menuItem: ContextMenuItem<T>,
  handleClick,
) {
  return (
    <MenuItem
      key={key}
      disabled={menuItem.disabled}
      onClick={handleClick(menuItem)}
      onAuxClick={handleClick(menuItem)}
    >
      {renderItemBody(classes, menuItem)}
    </MenuItem>
  );
}

function renderItemBody<T>(
  classes,
  menuItem: ContextMenuGroup<T> | ContextMenuItem<T>
) {
  const Icon = menuItem.icon;
  return (
    <Fragment>
      {Icon ? (
        <ListItemIcon
          className={classNames(classes.itemIcon, {
            [classes.dangerous]: menuItem.dangerous,
          })}
        >
          <Icon />
        </ListItemIcon>
      ) : null}
      <ListItemText
        primary={(
          <Typography
            className={classNames({
              [classes.dangerous]: menuItem.dangerous,
            })}
            noWrap
          >
            {menuItem.title}
          </Typography>
        )}
      />
    </Fragment>
  );
}
