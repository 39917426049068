import {
  ownerDocument,
  useForkRef,
} from '@material-ui/core/utils';
import {isFunction} from 'lodash';
import React, {
  cloneElement,
  Component,
  createElement,
  Fragment,
  useEffect,
  useRef,
} from 'react';


// Modified clone on material-ui ClickAwayListener to expose the mouse and touch events.
// Otherwise, we can't get this to work with nested portal dropdowns.
export interface ClickAwayListenerProps {
  disableReactTree?: boolean;
  mouseEvent?: "onClick" | "onMouseDown" | "onMouseUp" | false;
  touchEvent?: "onTouchEnd" | "onTouchStart" | false;
  onClickAway: (e?: any) => void;
  children: React.ReactElement | ((props: ClickAwayListenerChildrenProps) => React.ReactNode);
}

export interface ClickAwayListenerChildrenProps extends NestedMenuProps {
  ref: any;
}

export interface NestedMenuProps {
  onClick: (e) => void;
  onTouchEnd: (e) => void;
}

/**
 * Listen for click events that occur somewhere in the document, outside of the element itself.
 * For instance, if you need to hide a menu when people click anywhere else on your page.
 */
export function ClickAwayListener({
  disableReactTree = false,
  mouseEvent = 'onClick',
  touchEvent = 'onTouchEnd',
  onClickAway,
  children,
}: ClickAwayListenerProps) {
  const movedRef = useRef(false);
  const nodeRef = useRef(null);
  const activatedRef = useRef(false);
  const syntheticEventRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      activatedRef.current = true;
    }, 0);
    return () => {
      activatedRef.current = false;
    };
  }, []);

  // @ts-ignore
  const handleRef = useForkRef(children.ref, nodeRef);

  return createElement(ClickAwayListenerInternal, {
    disableReactTree,
    mouseEvent,
    touchEvent,
    movedRef,
    nodeRef,
    activatedRef,
    syntheticEventRef,
    handleRef,
    onClickAway,
    children,
  });
}

interface ClickAwayListenerInternalProps {
  disableReactTree?: boolean;
  mouseEvent: "onClick" | "onMouseDown" | "onMouseUp" | false;
  touchEvent: "onTouchEnd" | "onTouchStart" | false;
  onClickAway: (e?: any) => void;
  children: React.ReactElement | ((props: ClickAwayListenerChildrenProps) => React.ReactNode);

  [key: string]: any;
}

class ClickAwayListenerInternal extends Component<ClickAwayListenerInternalProps> {
  public static defaultProps = {
    mouseEvent: 'onClick',
    touchEvent: 'onTouchEnd',
  };

  private unregisterMouse: Function | null;
  private unregisterTouch: Function | null;

  constructor(props) {
    super(props);

    this.handleClickAway = this.handleClickAway.bind(this);
    this.createHandleSyntheticMouse = this.createHandleSyntheticMouse.bind(this);
    this.createHandleSyntheticTouch = this.createHandleSyntheticTouch.bind(this);
  }

  componentDidMount() {
    const {
      handleRef,
      nodeRef,
      movedRef,
      mouseEvent,
      touchEvent,
    } = this.props;

    if (touchEvent !== false) {
      const mappedTouchEvent = mapEventPropToEvent(touchEvent);
      // @ts-ignore
      const doc = ownerDocument(nodeRef.current);

      const handleTouchMove = () => {
        movedRef.current = true;
      };

      // @ts-ignore
      doc.addEventListener(mappedTouchEvent, this.handleClickAway);
      doc.addEventListener('touchmove', handleTouchMove);

      this.unregisterTouch = () => {
        // @ts-ignore
        doc.removeEventListener(mappedTouchEvent, this.handleClickAway);
        doc.removeEventListener('touchmove', handleTouchMove);
      };
    }

    if (mouseEvent !== false) {
      const mappedMouseEvent = mapEventPropToEvent(mouseEvent);
      // @ts-ignore
      const doc = ownerDocument(nodeRef.current);

      // @ts-ignore
      doc.addEventListener(mappedMouseEvent, this.handleClickAway);

      this.unregisterMouse = () => {
        // @ts-ignore
        doc.removeEventListener(mappedMouseEvent, this.handleClickAway);
      };
    }
  }

  componentWillUnmount() {
    if (this.unregisterMouse) this.unregisterMouse();
    if (this.unregisterTouch) this.unregisterTouch();
  }

  handleClickAway(event) {
    const {
      disableReactTree,
      movedRef,
      nodeRef,
      activatedRef,
      syntheticEventRef,
      onClickAway,
    } = this.props;

    const insideReactTree = syntheticEventRef.current;
    syntheticEventRef.current = false;

    // @ts-ignore
    const doc = ownerDocument(nodeRef.current);

    if (!activatedRef.current || !nodeRef.current || clickedRootScrollbar(event, doc)) {
      return;
    }

    // Do not act if user performed touchmove
    if (movedRef.current) {
      movedRef.current = false;
      return;
    }

    let insideDOM;

    // If not enough, can use https://github.com/DieterHolvoet/event-propagation-path/blob/master/propagationPath.js
    if (event.composedPath) {
      insideDOM = event.composedPath().indexOf(nodeRef.current) > -1;
    } else {
      // @ts-ignore
      insideDOM = !doc.documentElement.contains(event.target) || nodeRef.current.contains(event.target);
    }

    if (!insideDOM && (disableReactTree || !insideReactTree)) {
      onClickAway(event);
    }
  }

  createHandleSyntheticMouse(event) {
    const {
      syntheticEventRef,
      mouseEvent,
      children,
    } = this.props;
    syntheticEventRef.current = true;

    if (mouseEvent !== false && !isFunction(children)) {
      const childrenPropsHandler = children.props[mouseEvent];
      if (childrenPropsHandler) {
        childrenPropsHandler(event);
      }
    }
  }

  createHandleSyntheticTouch(event) {
    const {
      syntheticEventRef,
      touchEvent,
      children,
    } = this.props;
    syntheticEventRef.current = true;

    if (touchEvent !== false && !isFunction(children)) {
      const childrenPropsHandler = children.props[touchEvent];
      if (childrenPropsHandler) {
        childrenPropsHandler(event);
      }
    }
  }

  render() {
    const {
      handleRef,
      mouseEvent,
      touchEvent,
      children,
    } = this.props;

    if (isFunction(children)) {
      return children({
        ref: handleRef,
        onClick: this.createHandleSyntheticMouse,
        onTouchEnd: this.createHandleSyntheticTouch,
      });
    } else {
      const childrenProps = {
        ref: handleRef,
      };

      if (mouseEvent !== false) {
        childrenProps[mouseEvent] = this.createHandleSyntheticMouse;
      }

      if (touchEvent !== false) {
        childrenProps[touchEvent] = this.createHandleSyntheticTouch;
      }

      return <Fragment>{cloneElement(children, childrenProps)}</Fragment>;
    }
  }
}

function mapEventPropToEvent(eventProp) {
  return eventProp.substring(2).toLowerCase();
}

function clickedRootScrollbar(event, doc) {
  return (
    doc.documentElement.clientWidth < event.clientX ||
    doc.documentElement.clientHeight < event.clientY
  );
}
