import {ObservableQuery} from '@apollo/client';
import {callRefetch} from '@app-lib/apollo/callRefetch';
import {AppServicesContext} from '@app-lib/AppServicesProvider';
import {AppActions} from '@app-lib/redux/actions';
import {
  logError,
  logMessage,
} from '@app-system/logging';
import {isUndefined} from 'lodash';
import {
  MutableRefObject,
  useContext,
  useEffect,
} from 'react';
import {
  concat,
  fromEvent,
  interval,
  merge,
  NEVER,
  of,
} from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';
import {getPageVisibility} from '../utils/browser';


export interface UseActionChannelPollingOptions {
  actionTriggers?: (action: AppActions) => boolean
  refetch: MutableRefObject<ObservableQuery["refetch"] | undefined>
  called?: boolean
}

const DefaultPollingInterval = 60000;

const PollingAction = {
  type: 'polling',
} as const;

export function useActionChannelPolling({
  actionTriggers,
  refetch,
  called = true,
}: UseActionChannelPollingOptions) {
  const appServices = useContext(AppServicesContext);

  useEffect(() => {
    if (!called) return;
    let effectOff = false;
    const {
      visible$,
      hidden$,
    } = buildVisibilityObservables();

    const online$ = navigator.onLine
      ? concat(of(true), fromEvent(window, 'online'))
      : fromEvent(window, 'online');

    const action$ = actionTriggers
      ? fromEvent(appServices.messengers.actionsChannel, 'message')
        .pipe(filter(actionTriggers))
      : NEVER;

    const sub = online$
      .pipe(
        mergeMap(() =>
          visible$
            .pipe(
              mergeMap(visible => (
                merge(
                  interval(DefaultPollingInterval).pipe(map(() => PollingAction)),
                  action$,
                ).pipe(
                  throttleTime(DefaultPollingInterval / 4, void 0, {leading: true, trailing: true}),
                  takeUntil(merge(
                    hidden$,
                    fromEvent(window, 'offline'),
                  )),
                )
              ))
            )
        ),
        switchMap(async (action) => {
          await callRefetch(refetch);
          return null;
        }),
      )
      .subscribe({
        error: logError,
        complete: () => {
          if (!effectOff) {
            logMessage('ActionChannelPolling stopped unexpectedly!', void 0, 'warn');
          }
        },
      });

    return () => {
      effectOff = true;
      sub.unsubscribe();
    };
  }, [called]);
}

export function buildVisibilityObservables() {
  const {hidden, visibilityChange} = getPageVisibility();
  if ((!isUndefined(document.addEventListener) && hidden)) {
    const visiblility$ = concat(of(true), fromEvent(document, visibilityChange))
      .pipe(
        map(() => !document[hidden]),
      );

    return {
      visible$: visiblility$.pipe(
        filter(visible => !!visible),
      ),
      hidden$: visiblility$.pipe(
        filter(visible => !visible),
      ),
    };

  } else {
    return {
      visible$: of(true),
      hidden$: NEVER,
    };
  }
}
