import {NotificationPanel} from '@app-features/notifications/components/NotificationPanel';
import {
  Badge,
  ButtonProps,
  Drawer,
  Fade,
  IconButton,
  Popper,
  useMediaQuery,
} from '@material-ui/core';
import {
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {Notifications as NotificationsIcon} from '@material-ui/icons';
import React, {
  Fragment,
  useContext,
  useRef,
} from 'react';
import {UserNotificationsContext} from './container';


export interface NotificationButtonProps {
  color?: ButtonProps["color"],
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  popper: {
    width: 400,
  },
}));

const popperOptions = {
  preventOverflow: true,
  boundariesElement: 'window',
};

// TODO: implement container logic to handle actions in panel.
export function NotificationButton({
  color,
}: NotificationButtonProps) {
  const classes = useStyles();
  const theme = useTheme();
  const usePopper = typeof window !== 'undefined' ? useMediaQuery(theme.breakpoints.up('sm')) : true;
  const data = useContext(UserNotificationsContext);
  const buttonRef = useRef();

  return (
    <Fragment>
      <IconButton
        aria-label={renderMessage(data.unreadCount)}
        color={color}
        buttonRef={buttonRef}
        onClick={data.handleOpenNotifications}
      >
        <Badge
          badgeContent={data.unreadCount}
          color="secondary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Fragment>
        {usePopper ? (
          <Popper
            className={classes.popper}
            anchorEl={buttonRef.current}
            placement="bottom-end"
            transition
            open={!!data.showNotifications}
            popperOptions={popperOptions}
          >
            {({TransitionProps}) => (
              <Fade {...TransitionProps} timeout={350}>
                <div>
                  Show the panel
                  <NotificationPanel
                    notifications={data.notifications}
                    onClose={data.handleCloseNotifications}
                  />
                </div>
              </Fade>
            )}
          </Popper>
        ) : (
          <Drawer
            anchor="top"
            open={!!data.showNotifications}
            onClose={data.handleCloseNotifications}
          >
            <NotificationPanel
              notifications={data.notifications}
            />
          </Drawer>
        )}
      </Fragment>
    </Fragment>
  );
}

function renderMessage(count) {
  if (count === 0) {
    return `You have no new notifications`;
  } else {
    return `Show ${count} new notification${count === 1 ? '' : 's'}`;
  }
}
