import {ScrollBox} from '@app-components/ScrollBox';
import {INode} from '@app-lib/apollo/localTypes';
import {getListId} from '@app-lib/collections/utils';
import {
  mapRelayList,
  RelayListProps,
  SecondaryActionList,
} from '@app-lib/components/lists';
import {
  List,
  Theme,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  get,
  isArray,
  isFunction,
} from 'lodash';
import memoize from 'memoize-one';
import React, {
  memo,
  useCallback,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  areEqual,
  FixedSizeList,
  VariableSizeList,
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';


// Standard example hook
export const createItemData = memoize(createRelayListItemData);

export function createRelayListItemData(
  list,
  itemSecondaryActionIcon,
  onClickItemSecondaryAction,
  useSelect,
  selected,
  onSelectItem,
  onClickItem,
  onDoubleClickItem,
  onAuxClickItem,
  onContextMenuItem,
) {
  const items = list ? mapRelayList(list) : [];
  const hasMoreItems = get(list, 'pageInfo.hasNextPage');

  return {
    list: items,
    loading: !list,
    hasMoreItems,
    itemCount: getListItemCount(items, hasMoreItems),
    itemSecondaryActionIcon,
    onClickItemSecondaryAction,
    useSelect,
    selected,
    onSelectItem,
    onClickItem,
    onDoubleClickItem,
    onAuxClickItem,
    onContextMenuItem,
  };
}

export function getListItemCount<M>(items: Array<M>, hasMoreItems?: boolean) {
  return hasMoreItems ? items.length + 1 : items.length;
}

export interface WithPropsArgs<T> {
  key: string,
  model: T,
  data,
}

export interface MakeRowOpts<T> {
  withProps: (args: WithPropsArgs<T>) => any,
  withSkeletonProps?: (args: WithPropsArgs<T>) => any,
  getKey?: (model: any, index: number) => string,
}

const defaultGetKey = (model, index) => model.id;

// most lists use this so we're putting this here to keep duplication down.
const defaultWithSkeletonProps = ({data}) => ({
  useSelect: data.useSelect,
  secondaryActionIcon: data.itemSecondaryActionIcon,
});

export function makeRowComponent<T>(ItemComponent: React.ComponentType<any>, opts: MakeRowOpts<T>) {
  const {
    withProps,
    withSkeletonProps = defaultWithSkeletonProps,
    getKey = defaultGetKey,
  } = opts;

  // @ts-ignore
  return memo(({index, data, style}) => {
    const {
      loading,
      hasMoreItems,
      list,
    } = data;
    const model = list[index];
    const key = model ? getKey(model, index) : '' + index;
    if (!model && (loading || hasMoreItems)) {
      return (
        <div style={style}>
          <ItemComponent {...withSkeletonProps({key, model, data})} />
        </div>
      );
    } else if (!model) {
      return (
        <div style={style} />
      );
    }

    return (
      <div style={style}>
        <ItemComponent{...withProps({key, model, data})} />
      </div>
    );
  }, areEqual);
}

export interface InfiniteScrollableListProps<T extends INode> extends RelayListProps<T>,
  SecondaryActionList<T> {
  className?: string;
  domRef?: (ref: HTMLUListElement) => void;
  itemSize?: ((index: number) => number) | number;
  overscanCount?: number;
  itemData;
  RowComponent: React.ComponentType<any>;
  onContextMenu?: (e: React.MouseEvent<any>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    height: '100%',
  },
}));

export function InfiniteScrollableList<T extends INode>({
  className,
  domRef,
  simpleList,
  // TODO: get actual default based on simple item size calculated from theme.
  itemSize = 45,
  overscanCount = 10,
  RowComponent,
  list,
  itemData,
  loadMoreItems,
  onContextMenu,
}: InfiniteScrollableListProps<T>) {
  const classes = useStyles();
  const isItemLoaded = useCallback(
    (index) => list && !isArray(list) && !!list.edges[index],
    [list],
  );
  const itemKey = useCallback(
    (index) => {
      if (list && isArray(list)) {
        return list[index] ? getListId(list[index]) : index;
      } else if (list && list.edges[index]) {
        return getListId(list.edges[index].node);
      } else {
        return index;
      }
    },
    [list],
  );

  if (!list) {
    return (
      <ScrollBox className={className}>
        <List
          className={classes.list}
          ref={domRef}
          onContextMenu={onContextMenu}
        >
          <RowComponent
            index={0}
            data={itemData}
          />
          <RowComponent
            index={1}
            data={itemData}
          />
          <RowComponent
            index={2}
            data={itemData}
          />
        </List>
      </ScrollBox>
    );
  } else if (typeof window === 'undefined' || simpleList) {
    return (
      <ScrollBox className={className}>
        <List
          className={classes.list}
          ref={domRef}
          onContextMenu={onContextMenu}
        >
          {mapRelayList(list, (item, index) => (
            <RowComponent
              key={itemKey(index)}
              index={index}
              data={itemData}
            />
          ))}
        </List>
      </ScrollBox>
    );
  } else if (isArray(list)) {
    return (
      <ScrollBox className={className}>
        <List
          className={classes.list}
          ref={domRef}
          onContextMenu={onContextMenu}
        >
          <AutoSizer>
            {({height, width}) => {
              if (isFunction(itemSize)) {
                return (
                  <VariableSizeList
                    height={height}
                    width={width}
                    itemSize={itemSize}
                    itemCount={itemData.itemCount}
                    itemData={itemData}
                    itemKey={itemKey}
                  >
                    {RowComponent}
                  </VariableSizeList>
                );
              } else {
                return (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemSize={itemSize}
                    itemCount={itemData.itemCount}
                    itemData={itemData}
                    itemKey={itemKey}
                  >
                    {RowComponent}
                  </FixedSizeList>
                );
              }
            }}
          </AutoSizer>
        </List>
      </ScrollBox>
    );
  }

  // list is a Connection
  return (
    <ScrollBox className={className}>
      <List
        className={classes.list}
        ref={domRef}
        onContextMenu={onContextMenu}
      >
        <AutoSizer>
          {({height, width}) => (
            <InfiniteLoader
              itemCount={itemData.itemCount}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
            >
              {({onItemsRendered, ref}) => {
                if (isFunction(itemSize)) {
                  return (
                    <VariableSizeList
                      ref={ref}
                      height={height}
                      width={width}
                      overscanCount={overscanCount}
                      itemSize={itemSize}
                      itemCount={itemData.itemCount}
                      itemData={itemData}
                      itemKey={itemKey}
                      onItemsRendered={onItemsRendered}
                    >
                      {RowComponent}
                    </VariableSizeList>
                  );
                } else {
                  return (
                    <FixedSizeList
                      ref={ref}
                      height={height}
                      width={width}
                      overscanCount={overscanCount}
                      itemSize={itemSize}
                      itemCount={itemData.itemCount}
                      itemData={itemData}
                      itemKey={itemKey}
                      onItemsRendered={onItemsRendered}
                    >
                      {RowComponent}
                    </FixedSizeList>
                  );
                }
              }}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </List>
    </ScrollBox>
  );
}
