import {NestedMenuProps} from '@app-components/ClickAwayListener';
import {PortalPopper} from '@app-components/PortalDropdown';
import {SearchInput} from '@app-components/SearchInput';
import {OrganizationFragmentData} from '@app-features/organizations/types';
import {ListItemMouseEvent} from '@app-lib/components/lists';
import {
  Box,
  ListItem,
  ListItemText,
  Theme,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ReferenceObject} from 'popper.js';
import React, {
  Fragment,
  useCallback,
} from 'react';
import {OrganizationList} from '../OrganizationList';
import {TenantSelectorHook} from './container';


export interface TenantSelectorProps {
  tenantSelector: TenantSelectorHook;
  onSelectTenant?: (e: ListItemMouseEvent<OrganizationFragmentData>) => void;
}

export interface TenantSelectorDropdownProps extends NestedMenuProps {
  open?: boolean;
  tenantSelector: TenantSelectorHook;
  anchorElement: ReferenceObject | null;
  onClose: (e: any) => void;
  onSelectTenant?: (e: ListItemMouseEvent<OrganizationFragmentData>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    paddingTop: theme.spacing(1),
  },
  list: {
    height: 300,
    width: 300,
    padding: 0,
  },
}));

export function TenantSelector({
  tenantSelector,
  onSelectTenant,
}: TenantSelectorProps) {
  const classes = useStyles();

  if (tenantSelector.error) {
    // TODO: render appropriate warning to user that the list failed to load.
    return (
      <ListItem>
        <ListItemText primary="An error occurred!" />
      </ListItem>
    );
  }

  return (
    <Fragment>
      <Box className={classes.search} px={2} mt={2}>
        <SearchInput
          value={tenantSelector.searchText}
          // eslint-disable-next-line react/jsx-handler-names
          onChange={tenantSelector.onChangeSearchText}
        />
      </Box>
      <Box className={classes.list} px={2} mt={2}>
        <OrganizationList
          {...(tenantSelector.organizations || {})}
          useSelect
          onClickItem={onSelectTenant}
        />
      </Box>
    </Fragment>
  );
}


export function TenantSelectorDropdown({
  open = false,
  tenantSelector,
  anchorElement,
  onSelectTenant,
  onClose,
  onClick,
  onTouchEnd,
}: TenantSelectorDropdownProps) {
  const handleSelectTenant = useCallback((e) => {
    onSelectTenant && onSelectTenant(e);
    onClose && onClose(e);
  }, [onSelectTenant, onClose]);

  if (!anchorElement) {
    return null;
  }
  return (
    <PortalPopper
      open={open}
      onClose={onClose}
      placement="right-start"
      anchorEl={anchorElement}
    >
      {({handleClose}) => (
        <Box onClick={onClick} onTouchEnd={onTouchEnd}>
          <TenantSelector
            tenantSelector={tenantSelector}
            onSelectTenant={handleSelectTenant}
          />
        </Box>
      )}
    </PortalPopper>
  );
}
