import {QueryResult} from '@apollo/client';
import {MutableRefObject} from 'react';


export async function callRefetch<TData, TVariables>(
  refetchRef: MutableRefObject<QueryResult<TData, TVariables>["refetch"] | undefined>,
  queryVariables?: TVariables,
) {
  const _refetch = refetchRef.current;
  if (_refetch) {
    try {
      if (queryVariables) {
        await _refetch(queryVariables);
      } else {
        await _refetch();
      }
    } catch (e) {
      console.log('_refetch', _refetch);
      console.error('failed to refetch data for page', e);
    }
  } else {
    console.error('refetch is not longer available for page');
  }
}
