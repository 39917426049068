import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  PopperPlacementType,
} from '@material-ui/core';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {isFunction} from 'lodash';
import React, {
  cloneElement,
  Fragment,
  useCallback,
  useRef,
  useState,
} from 'react';


export interface DropdownProps {
  disabled?: boolean
  placement?: PopperPlacementType
  anchorElement: React.ReactElement | ((props: AnchorElementProps) => React.ReactNode)
  onOpen?: () => void
  onClose?: () => void
  children?: React.ReactNode | Function
}

interface AnchorElementProps {
  open: boolean
  onClick: (e?: any) => void
  ref: React.Ref<any>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  filterDropdown: {
    zIndex: 9,
    minWidth: 250,
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AddExtraProps = (Component, extraProps) => {
  return cloneElement(
    Component,
    extraProps
  );
};

const popperOptions = {
  preventOverflow: true,
  boundariesElement: 'window',
};

export function Dropdown({
  disabled = false,
  placement = 'bottom',
  anchorElement,
  onOpen,
  onClose,
  children,
}: DropdownProps) {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>();
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(
    () => {
      if (!disabled) {
        setOpen(prev => {
          const next = !prev;
          if (next) {
            onOpen && onOpen();
          } else {
            onClose && onClose();
          }
          return next;
        });
      }
    },
    [disabled, onOpen, onClose],
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      if (event && anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  return (
    <Fragment>
      {isFunction(anchorElement)
        ? anchorElement({open, onClick: handleToggle, ref: anchorRef})
        : AddExtraProps(anchorElement, {
          ref: anchorRef,
          onClick: handleToggle,
        })}
      <Popper
        className={classes.filterDropdown}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement={placement}
        popperOptions={popperOptions}
      >
        {({TransitionProps}) => (
          <Grow{...TransitionProps}>
            <Paper>
              <ClickAwayListener
                onClickAway={handleClose}
              >
                {isFunction(children) ? children({handleClose, open}) : children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
