import React, {
  createContext,
  useMemo,
} from 'react';
import {
  useNotifications,
  UseNotificationsHook,
} from './load-notifications';
import {
  useUnreadNotifications,
  UseUnreadNotificationsHook,
} from './load-unread-notification';


export interface UserNotificationsProviderProps {
  children: React.ReactNode,
}

export interface UserNotificationsContainer extends UseNotificationsHook,
  UseUnreadNotificationsHook {
}

export const UserNotificationsContext = createContext<UserNotificationsContainer>({
  unreadCount: 0,
});

export function UserNotificationsProvider(props: UserNotificationsProviderProps) {
  const notifications = useNotifications();
  const unread = useUnreadNotifications();

  const data = useMemo(() => ({
    ...notifications,
    ...unread,
  }), [
    notifications,
    unread,
  ]);

  return (
    <UserNotificationsContext.Provider value={data}>
      {props.children}
    </UserNotificationsContext.Provider>
  );
}
