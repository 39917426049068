import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'clsx';
import React from 'react';


export interface ScrollBoxProps {
  className?: string;
  domRef?: React.Ref<any>;
  scrollY?: boolean;
  onContextMenu?: (e?: any) => void;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  scrollY: {
    overflowY: 'auto',
  },
}));

export function ScrollBox({
  className,
  domRef,
  scrollY = false,
  onContextMenu,
  children,
}: ScrollBoxProps) {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, className)}
      ref={domRef}
      onContextMenu={onContextMenu}
    >
      <div className={classNames(classes.absolute, {[classes.scrollY]: scrollY})}>
        {children}
      </div>
    </div>
  );
}
