import {ClickableListItem} from '@app-components/ClickableListItem';
import {OrganizationFragmentData} from '@app-features/organizations/types';
import {stopPropagation} from '@app-lib/components/events';
import {
  ClickableItemProps,
  getListItemMouseEvent,
  ItemWithSecondaryAction,
  MultiSelectableItemProps,
} from '@app-lib/components/lists';
import {
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Business as BusinessIcon} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import React, {useCallback} from 'react';


export interface OrganizationItemProps extends ClickableItemProps<OrganizationFragmentData>,
  ItemWithSecondaryAction<OrganizationFragmentData>,
  MultiSelectableItemProps<OrganizationFragmentData> {
  organization?: OrganizationFragmentData | any,
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: 'inherit',
  },
}));

export function OrganizationItem({
  organization,
  useSelect = false,
  selected = false,
  onClick,
  onDoubleClick,
  onAuxClick,
  onContextMenu,
  onSelect,
  // get Typescript to not complain.
  secondaryActionIcon: SecondaryActionIcon,
  onClickSecondaryAction,
}: OrganizationItemProps) {
  const classes = useStyles();

  const handleClickSecondaryAction = useCallback((e) => {
    onClickSecondaryAction && onClickSecondaryAction(getListItemMouseEvent(e, organization));
  }, [organization, onClickSecondaryAction]);

  const handleSelect = useCallback((e) => {
    onSelect && onSelect({model: organization, selected: e.target.checked});
  }, [organization, onSelect]);

  return (
    <ClickableListItem
      button={!!onClick || !!onDoubleClick}
      alignItems="center"
      model={organization}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onAuxClick={onAuxClick}
      onContextMenu={onContextMenu}
    >
      {useSelect ? (
        <ListItemIcon onClick={stopPropagation}>
          <Checkbox
            edge="start"
            checked={selected}
            onChange={handleSelect}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
      ) : null}

      <ListItemIcon className={classes.icon}>
        <BusinessIcon />
      </ListItemIcon>

      <ListItemText
        primary={organization ? (
          <Typography noWrap>
            {organization.name}
          </Typography>
        ) : (
          <Skeleton />
        )}
      />

      {SecondaryActionIcon && (
        <ListItemSecondaryAction>
          <IconButton color="inherit" onClick={handleClickSecondaryAction}>
            <SecondaryActionIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ClickableListItem>
  );
}
