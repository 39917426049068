import {AppSettings} from '@app-features/settings/forms/AppSettings';
import React from 'react';
import {MainLayoutFormsState} from './state/forms';


export interface MainLayoutFormsProps {
  formState: MainLayoutFormsState,
  onClose: () => void,
}

export function MainLayoutForms({
  formState,
  onClose,
}: MainLayoutFormsProps) {
  switch (formState.open) {
    case null:
      return null;
    case 'APP_SETTINGS':
      return (
        <AppSettings
          onClose={onClose}
          onSuccess={onClose}
        />
      );
    default:
      return null;
  }
}
