import {buildQueryVariablesReducer} from '@app-lib/components/pages/queries';
import {isEqual} from 'lodash';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';


export interface UseFilterStateProps<F, E, V extends F> {
  value: V,
  defaultValue: V,
  onChange?: (value: F) => void,
  pickFilterProps?: (value: V) => F,
}

export function useFilterState<F, E, V extends F = F>({
  value,
  defaultValue,
  onChange,
  pickFilterProps = (value: V) => value,
}: UseFilterStateProps<F, E, V>) {
  const [filters, setFilters] = useState(pickFilterProps(value));
  useEffect(
    () => setFilters(pickFilterProps(value)),
    [value],
  );

  const handleDone = useCallback(
    (handleClose) => () => {
      if (onChange && !isEqual(filters, pickFilterProps(value))) {
        onChange(filters);
      }
      handleClose();
    },
    [onChange, filters, value],
  );

  const handleReset = useCallback(
    (handleClose) => () => {
      if (onChange && !isEqual(pickFilterProps(value), pickFilterProps(defaultValue))) {
        onChange(defaultValue);
      } else {
        setFilters(pickFilterProps(defaultValue));
      }
      handleClose();
    },
    [onChange, value],
  );

  return {
    filters,
    setFilters,
    handleDone,
    handleReset,
  };
}


export interface TextOnlyQueryVariables {
  first: number,
  searchText: string | null,
}

export type TextOnlyContentsFilter = Omit<TextOnlyQueryVariables,
  | "first">

export const defaultTextOnlyContentsFilter: TextOnlyContentsFilter = {
  searchText: null,
};

export const textOnlyQueryReducer = buildQueryVariablesReducer<TextOnlyQueryVariables>(null);
