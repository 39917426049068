import {initialState as uploadManagerInitialState} from '@app-features/file-tree/redux/upload-manager/reducer';
import {UserNotificationsProvider} from '@app-features/notifications/components/NotificationButton/container';
import {useFileUploadQueueMonitorStats} from '@app-system/upload-manager/services/FileUploadQueueMonitor';
import {MonitorStats} from '@forks/swmq';
import React, {
  createContext,
  useMemo,
} from 'react';
import {
  useUserSession,
  UseUserSessionHook,
} from './load-user';
import {
  useUserMenu,
  UseUserMenuHook,
} from './user-menu';


export interface MainLayoutProviderProps {
  children: React.ReactNode,
}

export interface MainLayoutContainer extends UseUserSessionHook,
  UseUserMenuHook {
  uploadStats: MonitorStats;
}

export const MainLayoutContext = createContext<MainLayoutContainer>({
  uploadStats: uploadManagerInitialState.stats,
});

export function MainLayoutProvider(props: MainLayoutProviderProps) {
  const userSession = useUserSession();
  const userMenu = useUserMenu();
  const uploadStats = useFileUploadQueueMonitorStats();

  const data = useMemo(() => ({
    ...userSession,
    ...userMenu,
    uploadStats,
  }), [
    userSession,
    userMenu,
    uploadStats,
  ]);

  return (
    <MainLayoutContext.Provider value={data}>
      <UserNotificationsProvider>
        {props.children}
      </UserNotificationsProvider>
    </MainLayoutContext.Provider>
  );
}
