import {NestedMenuProps} from '@app-components/ClickAwayListener';
import {TenantSelectorDropdown} from '@app-features/organizations/components/TenantSelector';
import {TenantSelectorHook} from '@app-features/organizations/components/TenantSelector/container';
import {OrganizationFragmentData} from '@app-features/organizations/types';
import {
  getListItemMouseEvent,
  ListItemMouseEvent,
} from '@app-lib/components/lists';
import {TenantState} from '@app-system/tenant/redux/reducer';
import {
  Divider,
  Link as MuiLink,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  Business as BusinessIcon,
  DeviceHub as DeviceHubIcon,
  ExitToApp as ExitToAppIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  Sync as SyncIcon,
} from '@material-ui/icons';
import Link from 'next/link';
import React, {
  forwardRef,
  useCallback,
  useRef,
  useState,
} from 'react';


export interface UserMenuProps extends NestedMenuProps {
  currentTenant?: OrganizationFragmentData | null;
  tenants: Array<OrganizationFragmentData>;
  hasMoreTenants?: boolean;
  tenantSelector: TenantSelectorHook;
  tenantState: TenantState;
  onSelectTenant: (e: ListItemMouseEvent<OrganizationFragmentData>) => void;
  onOpenSettings: (e?: any) => void;
  onLogout: (e: any) => void;
  onClose: (e?: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 230,
    // mimic Menu
    maxHeight: 'calc(100vh - 96px)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`,
  },
  listItemIcon: {
    minWidth: '2rem',
    maxWidth: '2rem',
    color: 'inherit',
  },
  currentOrganizationName: {
    color: theme.palette.secondary.main,
  },
  divider: {
    marginBottom: theme.spacing(1) / 2,
    marginTop: theme.spacing(1) / 2,
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

export const UserMenu = forwardRef(({
  currentTenant,
  tenants,
  hasMoreTenants = false,
  tenantSelector,
  tenantState,
  onSelectTenant,
  onOpenSettings,
  onLogout,
  onClose,
  onClick,
  onTouchEnd,
}: UserMenuProps, ref) => {
  const classes = useStyles();
  const hasSelectableTenants = tenants.length > 0;
  const handleOpenSettings = useCallback(() => {
    onOpenSettings && onOpenSettings();
    onClose && onClose();
  }, [onOpenSettings]);
  const [showMoreTenants, setShowMoreTenants] = useState(false);
  const moreTenantsAchor = useRef<HTMLLIElement | null>(null);
  const handleClickMore = useCallback(e => {
    tenantSelector.onLoadOrganizations();
    setShowMoreTenants(true);
  }, [tenantSelector.onLoadOrganizations]);
  const handleCloseMoreTenants = useCallback(e => setShowMoreTenants(false), []);

  const selectTenantDisabled = tenantState.loading;
  return (
    <Paper
      className={classes.root}
      ref={ref}
      onClick={onClick}
      onTouchEnd={onTouchEnd}
    >
      <MenuList>
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            <DeviceHubIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="Current Organization"
            secondary={
              <Typography
                className={classes.currentOrganizationName}
                variant="body2"
                noWrap
              >
                {currentTenant ? currentTenant.name : '(none)'}
              </Typography>
            }
          />
        </ListItem>
        <Divider className={classes.divider} variant="middle" />

        {hasSelectableTenants ? (
          <ListItem ref={moreTenantsAchor}>
            <ListItemIcon className={classes.listItemIcon}>
              <SyncIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Switch Organization
            </Typography>
            <TenantSelectorDropdown
              open={!!showMoreTenants}
              tenantSelector={tenantSelector}
              anchorElement={moreTenantsAchor.current}
              onSelectTenant={onSelectTenant}
              onClick={onClick}
              onTouchEnd={onTouchEnd}
              onClose={handleCloseMoreTenants}
            />
          </ListItem>
        ) : null}
        {hasSelectableTenants && tenants.map((tenant) => (
          <MenuItem
            key={tenant.id}
            disabled={selectTenantDisabled}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={e => onSelectTenant(getListItemMouseEvent(e, tenant))}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <BusinessIcon />
            </ListItemIcon>
            <Typography noWrap>
              {tenant.name}
            </Typography>
          </MenuItem>
        ))}
        {hasSelectableTenants && hasMoreTenants ? (
          <MenuItem disabled={selectTenantDisabled} onClick={handleClickMore}>
            <ListItemIcon className={classes.listItemIcon}>
              <div />
            </ListItemIcon>
            <Typography>{`More \u2026`}</Typography>
          </MenuItem>
        ) : null}
        {hasSelectableTenants ? (
          <Divider className={classes.divider} variant="middle" />
        ) : null}

        <MenuItem>
          <ListItemIcon className={classes.listItemIcon}>
            <PersonOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Link href="/profile" passHref>
            <MuiLink color="inherit">
              <Typography variant="inherit" noWrap>
                Profile
              </Typography>
            </MuiLink>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleOpenSettings}>
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Settings
          </Typography>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon className={classes.listItemIcon}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Logout
          </Typography>
        </MenuItem>
      </MenuList>
    </Paper>
  );
});

UserMenu.displayName = 'UserMenu';
