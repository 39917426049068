import {
  ApolloError,
  gql,
} from '@apollo/client';
import {useAuthQuery} from '@app-lib/apollo/useAuthQuery';
import {get} from 'lodash';


// TODO: get current unread count
const USER_UNREAD_NOTIFICATIONS = gql`
  query userUnreadNotifications {
    myNotifications(read: UNREAD) {
      total
    }
  }
`;

export interface UseUnreadNotificationsHook {
  unreadCount?: number,
  loadingUnread?: boolean,
  errorUnread?: ApolloError | null,
}

export function useUnreadNotifications(): UseUnreadNotificationsHook {
  // TODO: setup long polling
  const {data, loading, error} = useAuthQuery(USER_UNREAD_NOTIFICATIONS, {});

  return {
    unreadCount: get(data, 'myNotifications.total') || 0,
    loadingUnread: loading,
    errorUnread: error,
  };
}
