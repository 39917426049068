import {ListItemMouseEvent} from '@app-lib/components/lists';
import {MultiSelectionState} from '@app-lib/components/multi-select';
import {ReferenceObject} from 'popper.js';
import {
  ComponentType,
  useCallback,
} from 'react';


// goal: build a flexible menu that can handle a list of actions for a single object or a collection of mixed types of objects

// when a new item is selected we need to calculate which items to include in the list

export enum ContextActionInclusion {
  Include,
  Disabled,
  Skip,
  Exclude,
}

export interface ContextMenuItem<T> {
  group?: false
  title: string
  type: T
  icon?: ComponentType
  disabled: boolean
  dangerous: boolean
}

export interface ContextMenuGroup<T> {
  group: true
  title: string
  type: T
  icon?: ComponentType
  disabled: boolean
  dangerous: boolean
  items: Array<ContextMenuItem<T> | ContextMenuDivider>
}

/** Default empty context */
export interface ContextMenuContext {
}

export interface CurrentUserContextMenuContext extends ContextMenuContext {
  currentUser: string | null
}

export const initialCurrentUserContextMenuContext: CurrentUserContextMenuContext = {
  currentUser: null,
};

export interface ContextMenuState<T, M, C extends ContextMenuContext> {
  targets: Array<M>
  singleTargetModel?: M
  singleTargetMenuItems?: Array<ContextMenuGroup<T> | ContextMenuItem<T> | ContextMenuDivider>
  menuItems: Array<ContextMenuGroup<T> | ContextMenuItem<T> | ContextMenuDivider>
  show: false | ReferenceObject
  context: C
}

export function getInitialContextMenuState<T, M, C extends ContextMenuContext>(initialContext: C): ContextMenuState<T, M, C> {
  return {
    targets: [],
    menuItems: [],
    show: false,
    context: initialContext,
  };
}

export enum ContextMenuActionSelectionMode {
  SingleOnly,
  MultiselectOnly,
  Both,
}

export interface BaseContextMenuActionConfig<T> {
  group?: false
  type: T
  icon?: ComponentType
  title: string
  selectionMode: ContextMenuActionSelectionMode
  dangerous?: boolean
}

export type ContextMenuDivider = "ContextMenuDivider"
export const ContextMenuDivider = 'ContextMenuDivider' as "ContextMenuDivider";

export interface ContextMenuActionConfig<T, M, C extends ContextMenuContext> extends BaseContextMenuActionConfig<T> {
  include: (model: M, context: C) => ContextActionInclusion;
}

export interface ContextMenuGroupActionConfig<T, M, C extends ContextMenuContext> {
  group: true
  type: T
  icon?: ComponentType
  title: string
  selectionMode: ContextMenuActionSelectionMode
  dangerous?: boolean
  actionConfigs: Array<ContextMenuActionConfig<T, M, C> | ContextMenuDivider>
}

export interface ContextMenuConfig<T, M, C extends ContextMenuContext> {
  actionConfigs: Array<ContextMenuGroupActionConfig<T, M, C> | ContextMenuActionConfig<T, M, C> | ContextMenuDivider>
}

export interface ContextMenuUpdateTargetPayload<M> {
  targets: Array<M>
}

export interface ContextMenuUpdateContextPayload<C extends ContextMenuContext> {
  context: Partial<C>
}

export interface ContextMenuShowMenuPayload<M> extends ListItemMouseEvent<M> {
  singleTarget?: boolean
}

export interface ContextMenuHideMenuPayload {
  reset?: boolean
}

export type ContextMenuActions<M, C extends ContextMenuContext> =
  | { type: "UPDATE_CONTEXT_TARGETS", payload: ContextMenuUpdateTargetPayload<M> }
  | { type: "UPDATE_CONTEXT_MENU_CONTEXT", payload: ContextMenuUpdateContextPayload<C> }
  | { type: "SHOW_CONTEXT_MENU", payload: ContextMenuShowMenuPayload<M> }
  | { type: "HIDE_CONTEXT_MENU", payload: ContextMenuHideMenuPayload }
  | { type: "RESET_CONTEXT_MENU" }

export function isContextMenuAction<M, C extends ContextMenuContext>(action: { type: string }): action is ContextMenuActions<M, C> {
  switch (action.type) {
    case 'UPDATE_CONTEXT_TARGETS':
    case 'UPDATE_CONTEXT_MENU_CONTEXT':
    case 'SHOW_CONTEXT_MENU':
    case 'HIDE_CONTEXT_MENU':
    case 'RESET_CONTEXT_MENU':
      return true;
    default:
      return false;
  }
}

export function buildContextMenuReducer<T, M, C extends ContextMenuContext>(config: ContextMenuConfig<T, M, C>) {
  return (state: ContextMenuState<T, M, C>, action: ContextMenuActions<M, C>): ContextMenuState<T, M, C> => {
    switch (action.type) {
      case 'UPDATE_CONTEXT_TARGETS': {
        const targets = action.payload.targets;
        // TODO: this may need to cache the next set if the menu is currently open.
        //  otherwise, the menu may update while open.
        //  Need to decide if this is desirable or not.
        return {
          ...state,
          targets,
          menuItems: handleBuildMenuItems(config, targets, state.context),
        };
      }
      case 'UPDATE_CONTEXT_MENU_CONTEXT': {
        const context = action.payload.context;
        // TODO: this may need to cache the next set if the menu is currently open.
        //  otherwise, the menu may update while open.
        //  Need to decide if this is desirable or not.
        return {
          ...state,
          context: {
            ...state.context,
            ...context,
          },
          menuItems: handleBuildMenuItems(config, state.targets, context),
        };
      }
      case 'SHOW_CONTEXT_MENU':
        return handleShowContextMenu(config, state, action);
      case 'HIDE_CONTEXT_MENU':
        return handleHideContextMenu(state, action);
      case 'RESET_CONTEXT_MENU':
        return getInitialContextMenuState(state.context);
      default:
        return state;
    }
  };
}

interface MinSupportedState<T, M, C extends ContextMenuContext> extends MultiSelectionState {
  contextMenu: ContextMenuState<T, M, C>
}

export function buildContextMenuReducerDecorator<T,
  M,
  A extends { type: string, payload?: any },
  S extends MinSupportedState<T, M, C>,
  C extends ContextMenuContext>(
  baseReducer: (state: S, action: A) => S,
  contextMenuReducer: (state: ContextMenuState<T, M, C>, action: ContextMenuActions<M, C>) => ContextMenuState<T, M, C>,
  hasTargetsChanged: (prevState: S, nextState: S) => boolean,
  getTargets: (state: S) => Array<M> | null,
) {
  return function contextMenuReducerDecorator(state: S, action: A) {
    if (isContextMenuAction<M, C>(action)) {
      if (action.type === 'SHOW_CONTEXT_MENU') {
        const payload = (action as { type: "SHOW_CONTEXT_MENU", payload: ContextMenuShowMenuPayload<M> }).payload;
        if (!payload.singleTarget && state.multiSelect && payload.model) {
          // select this item
          const nextState = baseReducer(state, {
            type: 'MULTI_SELECTION_ITEM_EVENT',
            payload: {
              model: payload.model as M,
              selected: true,
              only: false,
            },
          } as A);
          // then update the context menu items
          const targets = getTargets(nextState);
          const nextStateB = targets ? {
            ...nextState,
            contextMenu: contextMenuReducer(nextState.contextMenu, {
              type: "UPDATE_CONTEXT_TARGETS",
              payload: {targets},
            }),
          } : nextState;
          // then open the menu
          return {
            ...nextStateB,
            contextMenu: contextMenuReducer(nextStateB.contextMenu, action),
          };
        }
      } else if (action.type === 'HIDE_CONTEXT_MENU') {
        const payload = (action as { type: "HIDE_CONTEXT_MENU", payload: ContextMenuHideMenuPayload }).payload;
        payload.reset = !state.multiSelect;
      }
      return {
        ...state,
        contextMenu: contextMenuReducer(state.contextMenu, action),
      };
    } else {
      const nextState = baseReducer(state, action);
      if (!nextState.multiSelect) {
        if (state.multiSelect === nextState.multiSelect) {
          return nextState;
        } else {
          return {
            ...nextState,
            contextMenu: getInitialContextMenuState(nextState.contextMenu.context),
          };
        }
      }

      if (hasTargetsChanged(state, nextState)
        || state.selection !== nextState.selection) {
        const targets = getTargets(nextState);
        if (targets) {
          return {
            ...nextState,
            contextMenu: contextMenuReducer(nextState.contextMenu, {
              type: "UPDATE_CONTEXT_TARGETS",
              payload: {targets},
            }),
          };
        }
      }

      return nextState;
    }
  };
}

export function useShowSingleTargetContextMenu<M,
  D extends (a: { type: "SHOW_CONTEXT_MENU", payload: ContextMenuShowMenuPayload<M> }) => void>(dispatch: D) {
  return useCallback(
    (e: ListItemMouseEvent<M>) => dispatch({
      type: 'SHOW_CONTEXT_MENU',
      payload: {
        ...e,
        // Force single-target.
        singleTarget: true,
      },
    }),
    [],
  );
}

function handleBuildMenuItems<T, M, C extends ContextMenuContext>(
  config: ContextMenuConfig<T, M, C>,
  targets: Array<M>,
  context: C,
): Array<ContextMenuGroup<T> | ContextMenuItem<T> | ContextMenuDivider> {
  const menuItems: Array<ContextMenuGroup<T> | ContextMenuItem<T> | ContextMenuDivider> = [];

  if (targets.length === 0) {
    return menuItems;
  }

  let addDivider = false;

  for (const actionConfig of config.actionConfigs) {
    const output = handleAddingMenuItems(actionConfig, targets, addDivider, menuItems, context);
    addDivider = output.addDivider;
  }

  return menuItems;
}

function handleAddingMenuItems<T, M, C extends ContextMenuContext>(
  actionConfig: ContextMenuGroupActionConfig<T, M, C> | ContextMenuActionConfig<T, M, C> | ContextMenuDivider,
  targets: Array<M>,
  addDivider: boolean,
  menuItems: Array<ContextMenuGroup<T> | ContextMenuItem<T> | ContextMenuDivider>,
  context: C,
): { addDivider: boolean } {
  if (actionConfig === ContextMenuDivider) {
    addDivider = true;
    return {
      addDivider,
    };
  }

  if (actionConfig.group) {
    let addChildDivider = false;
    const childMenuItems: Array<ContextMenuItem<T> | ContextMenuDivider> = [];
    for (const childActionConfig of actionConfig.actionConfigs) {
      const output = handleAddingMenuItems<T, M, C>(
        childActionConfig,
        targets,
        addChildDivider,
        childMenuItems,
        context,
      );
      addChildDivider = output.addChildDivider;
    }

    if (childMenuItems.length > 0) {
      if (addDivider) {
        menuItems.push(ContextMenuDivider);
        addDivider = false;
      }
      menuItems.push({
        group: true,
        type: actionConfig.type,
        icon: actionConfig.icon,
        title: actionConfig.title,
        disabled: false,
        dangerous: Boolean(actionConfig.dangerous),
        items: childMenuItems,
      });
    }

    return {
      addDivider,
    };
  }

  if (targets.length === 1) {
    // skip if MultiselectOnly
    if (actionConfig.selectionMode !== ContextMenuActionSelectionMode.MultiselectOnly) {
      const include = actionConfig.include(targets[0], context);
      if (include === ContextActionInclusion.Include || include === ContextActionInclusion.Disabled) {
        if (addDivider) {
          menuItems.push(ContextMenuDivider);
          addDivider = false;
        }
        menuItems.push({
          type: actionConfig.type,
          icon: actionConfig.icon,
          title: actionConfig.title,
          disabled: include === ContextActionInclusion.Disabled,
          dangerous: Boolean(actionConfig.dangerous),
        });
      }
    }
  } else if (actionConfig.selectionMode !== ContextMenuActionSelectionMode.SingleOnly) {
    let hasInclude = false;
    let hasDisabled = false;
    let include = ContextActionInclusion.Skip;
    for (const target of targets) {
      const result = actionConfig.include(target, context);
      if (result === ContextActionInclusion.Disabled) {
        hasDisabled = true;
      }
      if (result === ContextActionInclusion.Include || result === ContextActionInclusion.Disabled) {
        hasInclude = true;
      }
      if (result > include) {
        include = result;
      }
    }

    if (include === ContextActionInclusion.Include
      || (hasInclude && include === ContextActionInclusion.Skip)
      || include === ContextActionInclusion.Disabled) {
      if (addDivider) {
        menuItems.push(ContextMenuDivider);
        addDivider = false;
      }
      menuItems.push({
        type: actionConfig.type,
        icon: actionConfig.icon,
        title: actionConfig.title,
        disabled: hasDisabled,
        dangerous: Boolean(actionConfig.dangerous),
      });
    }
  }

  return {
    addDivider,
  };
}

function handleShowContextMenu<T, M, C extends ContextMenuContext>(
  config: ContextMenuConfig<T, M, C>,
  state: ContextMenuState<T, M, C>,
  action: { type: "SHOW_CONTEXT_MENU", payload: ContextMenuShowMenuPayload<M> },
): ContextMenuState<T, M, C> {
  const {clientX: x, clientY: y, model, singleTarget} = action.payload;
  const show = {
    clientHeight: y,
    clientWidth: x,
    getBoundingClientRect: (): ClientRect => ({
      bottom: y + 1,
      height: 1,
      left: x,
      right: x + 1,
      top: y,
      width: 1,
    }),
  };
  if (singleTarget && model) {
    return {
      ...state,
      singleTargetModel: model,
      singleTargetMenuItems: handleBuildMenuItems(config, [model], state.context),
      show,
    };
  }
  const hasModel = model && state.targets.includes(model);
  if (model) {
    // multi-select combo reducer is expected to be used to update targets if this item was new.
    const targets = hasModel ? state.targets : [model];
    return {
      ...state,
      targets,
      singleTargetModel: undefined,
      singleTargetMenuItems: undefined,
      menuItems: handleBuildMenuItems(config, targets, state.context),
      show,
    };
  } else {
    return {
      ...state,
      singleTargetModel: undefined,
      singleTargetMenuItems: undefined,
      show,
    };
  }
}

function handleHideContextMenu<T, M, C extends ContextMenuContext>(
  state: ContextMenuState<T, M, C>,
  action: { type: "HIDE_CONTEXT_MENU", payload: ContextMenuHideMenuPayload },
): ContextMenuState<T, M, C> {
  const {reset} = action.payload;
  if (reset) {
    return getInitialContextMenuState(state.context);
  } else {
    return {
      ...state,
      singleTargetModel: undefined,
      singleTargetMenuItems: undefined,
      show: false,
    };
  }
}
