import {getFileIconPathForFile} from '@app-lib/icons/file-type-icons';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'clsx';
import React from 'react';


interface FileIconProps {
  className?: string,
  /** The file name to get the file type from. name is displayed as alt text. */
  name?: string,
}

export const useStyles = makeStyles({
  root: {},
});

/**
 * Component for adding an icon that indicates file type
 *
 * @param props
 */
export function FileIcon({
  className,
  name,
}: FileIconProps) {
  const classes = useStyles();

  return (
    <img
      className={classNames(classes.root, className)}
      alt={name}
      src={getFileIconPathForFile(name)}
    />
  );
}
