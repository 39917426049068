import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import {sortBy} from 'lodash';
import moment from 'moment-timezone';
import React, {ChangeEventHandler} from 'react';


export interface SelectTimeZoneProps {
  className?: string
  selectClassName?: string
  name?: string
  disabled?: boolean,
  value?: string
  onChange?: ChangeEventHandler<HTMLSelectElement>,
}

const timezones = moment.tz.names();
const utc = (zone: string) => moment.tz(zone).format('Z');
const sortedZones = sortBy(timezones.map((zone) => {
  const offset = utc(zone);
  return {
    zone,
    label: `(UTC ${offset}) ${zone})`,
    orderBy: Number.parseFloat(offset.replace(':', '.')) + 25,
  };
}), ['orderBy']);

export function SelectTimeZone({
  className,
  selectClassName,
  name,
  disabled,
  value,
  onChange,
}: SelectTimeZoneProps) {
  return (
    <FormControl className={className}>
      <NativeSelect
        name={name}
        disabled={disabled}
        value={value}
        className={selectClassName}
        onChange={onChange}
      >
        {sortedZones.map(({zone, label}) => (
          <option
            value={zone}
            key={zone}
          >
            {label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
