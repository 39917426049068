import {
  UploadManagerState,
  UploadMap,
} from '@app-features/file-tree/redux/upload-manager/reducer';
import {getUploadManager} from '@app-features/file-tree/redux/upload-manager/state';
import {
  FileUpload,
  UploadState,
} from '@app-lib/apollo/localTypes';
import {
  useMonitorData,
  useMonitorMeta,
  useMonitorStats,
} from '@app-lib/swmq';
import {JobState, initialMeta} from '@forks/swmq';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {FileUploadQueueService} from './FileUploadQueueService';
import {FileUploadQueueName} from './state';
import {
  FileUploadJob,
  FileUploadJobData,
  FileUploadJobResponse,
} from './FileUploadQueueWorker';


export function useFileUploadQueueMonitor() {
  const uploadManager = useSelector(getUploadManager);
  if (FileUploadQueueService.isSupported()) {
    const monitor = useMonitorData<FileUploadJobData, FileUploadJobResponse>(FileUploadQueueName);

    const {jobs, stats} = monitor;
    const state: UploadManagerState = useMemo(() => {
      const files: UploadMap = {};
      const uploads: Array<FileUpload> = [];
      const visible: Array<FileUpload> = [];
      jobs.forEach(job => {
        if (job.data.cancel) return;
        const upload = buildFileUploadFromJob(job);
        files[upload.mutationId] = upload;
        uploads.push(upload);
        if (!upload.hide) {
          visible.push(upload);
        }
      });
      return {
        files,
        uploads,
        visible,
        stats,
        showManager: uploadManager.showManager,
      };
    }, [jobs, stats, uploadManager.showManager]);

    return state;
  } else {
    return uploadManager;
  }
}

export function useFileUploadQueueMonitorStats() {
  if (FileUploadQueueService.isSupported()) {
    return useMonitorStats<FileUploadJobData, FileUploadJobResponse>(FileUploadQueueName);
  } else {
    return useSelector(getUploadManager).stats;
  }
}

export function useFileUploadQueueMonitorMeta() {
  if (FileUploadQueueService.isSupported()) {
    return useMonitorMeta<FileUploadJobData, FileUploadJobResponse>(FileUploadQueueName);
  } else {
    return null;
  }
}

function buildFileUploadFromJob(job: FileUploadJob): FileUpload {
  const data = job.data.fileData;
  const shared = {
    __typename: 'FileUpload',
    name: data.name,
    mutationId: data.mutationId,
    file: data.file,
    parentId: data.parentId,
    path: data.path,
    pathIds: data.pathIds,

    jobId: job.id,
    dataUri: job.data.fileData.dataUri,
    hide: job.data.fileData.hide,
    ...mapJobMeta(job),
  } as const;

  if (data.parentType === 'FileContainer') {
    return {
      ...shared,
      parentType: 'FileContainer',
      fileContainerId: data.fileContainerId,
    };
  } else {
    return {
      ...shared,
      parentType: 'Folder',
    };
  }
}

function mapJobMeta(job: FileUploadJob): Pick<FileUpload, "status" | "startTime" | "percent" | "error" | "retryable"> {
  switch (job.status) {
    case JobState.Pending:
      return {
        status: UploadState.Pending,
        percent: null,
        startTime: job.timestamp,
      };
    case JobState.InProgress:
      return {
        status: UploadState.Uploading,
        percent: job.progress,
        startTime: job.timestamp,
      };
    case JobState.FailedPermanently:
      return {
        status: UploadState.Error,
        percent: job.progress,
        startTime: job.timestamp,
        retryable: false,
        error: job.failedReason,
      };
    case JobState.Complete:
      return {
        status: UploadState.Success,
        percent: job.progress,
        startTime: job.timestamp,
      };
  }
}
