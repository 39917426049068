import {Connection} from '@app-lib/apollo/apiTypeGenerics';
import produce from 'immer';
import {
  get,
  identity,
  isArray,
  isNil,
  isUndefined,
  pick,
  set,
} from 'lodash';
import React, {
  useCallback,
  useState,
} from 'react';


export interface PersistedMouseEvent extends Pick<React.MouseEvent<any>,
  | "screenX"
  | "screenY"
  | "clientX"
  | "clientY"
  | "pageX"
  | "pageY"
  | "ctrlKey"
  | "shiftKey"
  | "altKey"
  | "metaKey"
  | "getModifierState"
  | "button"
  | "buttons"
  | "movementX"
  | "movementY"> {
}

export interface ListItemMouseEvent<T> extends PersistedMouseEvent {
  model: T,
}

export type ListItemMouseCallback<T> = (e: ListItemMouseEvent<T>) => void;

export interface SelectorChangeEvent<T> extends PersistedMouseEvent {
  value: T,
}

export type SelectorChangeCallback<T> = (e: SelectorChangeEvent<T>) => void;

export const ListItemMouseEventDimensions = [
  'screenX',
  'screenY',
  'clientX',
  'clientY',
  'pageX',
  'pageY',
  'ctrlKey',
  'shiftKey',
  'altKey',
  'metaKey',
  'getModifierState',
  'button',
  'buttons',
  'movementX',
  'movementY',
];

export function getListItemMouseEvent<T>(e: React.MouseEvent<any>, model: T): ListItemMouseEvent<T> {
  // @ts-ignore
  const event = pick(e, ListItemMouseEventDimensions) as ListItemMouseEvent<T>;
  event.model = model;
  return event;
}

export interface ClickableItemProps<T> {
  itemRef?: React.MutableRefObject<any> | ((ref: any) => void),
  /** Called when the item is clicked. */
  onClick?: (e: ListItemMouseEvent<T>) => void,
  /** Called when the item is clicked. */
  onDoubleClick?: (e: ListItemMouseEvent<T>) => void,
  onAuxClick?: (e: ListItemMouseEvent<T>) => void,
  onContextMenu?: (e: ListItemMouseEvent<T>) => void,
}

export interface MultiSelectableOnSelectArgs<T> {
  model: T,
  selected: boolean,
}

export interface MultiSelectableItemProps<T> {
  /** When true, display a checkbox the user can select in the first column of the item. */
  useSelect?: boolean,
  /** Select mode is active but selection mechanism is in disabled state. */
  selectDisabled?: boolean,
  /** Whether the user is currently selected. */
  selected?: boolean,
  /** Called when the check box is toggled. */
  onSelect?: (args: MultiSelectableOnSelectArgs<T>) => void,
}

export interface ItemWithSecondaryAction<T> {
  secondaryActionIcon?: React.ComponentType<any>,
  onClickSecondaryAction?: (e: ListItemMouseEvent<T>) => void,
}

// signature for react-window-infinite-loader
export type VirtualLoadMoreItems = (startIndex: number, stopIndex: number) => Promise<void> | void;

/** Supports array and relay connection as list input */
export interface RelayListProps<T> {
  /** Use MuiList instead of InfiniteScrollableList */
  simpleList?: boolean,
  loading?: boolean,
  loadingMore?: boolean,
  list?: Array<T> | Connection<T> | null,
  hasMoreItems?: boolean,
  loadMoreItems?: VirtualLoadMoreItems,
}

export interface ListItemsActionsProps<T> {
  /** Called when the item in the list is left clicked/tapped. */
  onClickItem?: (e: ListItemMouseEvent<T>) => void,
  /** Called when the item in the list is double clicked. */
  onDoubleClickItem?: (e: ListItemMouseEvent<T>) => void,
  /** Called when the item in the list is middle clicked. aux event support. */
  onAuxClickItem?: (e: ListItemMouseEvent<T>) => void,
  /** Called when the item in the list is right clicked. contextmenu event support. */
  onContextMenuItem?: (e: ListItemMouseEvent<T>) => void,
}

export interface SecondaryActionList<T> {
  itemSecondaryActionIcon?: React.ComponentType<any>,
  onClickItemSecondaryAction?: (e: ListItemMouseEvent<T>) => void,
}

export function getRelayListTotal<T>(list: Array<T> | Connection<T>): number | undefined {
  if (isArray(list)) {
    return list.length;
  } else if (!isUndefined(list.total)) {
    return list.total;
  } else {
    const len = list?.edges?.length || 0;
    const hasMore = list?.pageInfo?.hasNextPage;
    return hasMore ? len + 1 : len;
  }
}

export enum ListPlaceholder {
  Empty = 'EMPTY',
  Filtered = 'FILTERED',
  List = 'LIST',
}

export function showPlaceholderForList<T>(
  list: Array<T> | Connection<T> | null | undefined,
  filtered: boolean,
): ListPlaceholder {
  if (isNil(list)) {
    return ListPlaceholder.List;
  }
  if (isRelayListEmpty(list)) {
    return filtered ? ListPlaceholder.Filtered : ListPlaceholder.Empty;
  }
  return ListPlaceholder.List;
}

export function isRelayListEmpty<T>(list?: Array<T> | Connection<T> | null): boolean {
  if (!list) {
    return true;
  } else if (isArray(list)) {
    return list.length === 0;
  } else {
    return (get(list, 'edges', []) || []).length === 0;
  }
}

export function mapRelayList<Input, Output = any>(
  list: Array<Input> | Connection<Input>,
  f: (item: Input, index: number) => Output = identity,
): Array<Output> {
  if (isArray(list)) {
    return list.map(f);
  } else {
    return (get(list, 'edges', []) || [])
      // @ts-ignore
      .map(({node}, idx) => f(node, idx));
  }
}

export interface SelectedItems {
  [key: string]: boolean,
}

export function arrayToSelection(value: Array<string> | null): SelectedItems {
  return (value || []).reduce((acc, it) => (acc[it] = true, acc), {});
}

export function selectionToArray(selected: SelectedItems): Array<string> | null {
  const value = Object.keys(selected);
  return value.length > 0 ? value : null;
}

export type SelectItemEvent<T> =
  | "RESET"
  | {
  model: T,
  selected: boolean,
  only?: boolean,
}

export interface MultiSelectableListProps<T> {
  /** When true, display a checkbox the user can select in the first column of the item. */
  useSelect?: boolean,
  /** Select mode is active but selection mechanism is in disabled state. */
  selectDisabled?: boolean,
  /** Whether the user is currently selected. */
  selected?: SelectedItems,
  /** Called when the check box is toggled. */
  onSelectItem?: (e: SelectItemEvent<T>) => void,
}

/** Check if the item is a list is selected */
export function isItemSelected(
  useSelect: boolean | undefined,
  key: string | undefined,
  selected: SelectedItems | undefined,
): boolean {
  if (!useSelect || !selected || !key) {
    return false;
  } else {
    return selected[key];
  }
}

export interface MultiSelectionChangeEvent<T> {
  itemEvent: SelectItemEvent<T>,
  selected: SelectedItems,
}

export interface UseMultiSelectionOpts<T> {
  initialValues?: SelectedItems,
  onSelectChange?: (e: MultiSelectionChangeEvent<T>) => void,
  getId?: (model: any) => string,
}

export function useMultiSelection<T = any>({
  initialValues = {},
  onSelectChange,
  getId = model => model.id,
}: UseMultiSelectionOpts<T> = {
  getId: model => model.id,
}) {
  const [selection, setSelection] = useState<SelectedItems>(initialValues);
  const onSelectItem = useCallback((event: SelectItemEvent<T>) => {
    if (event === 'RESET') {
      const nextSelection = {};
      setSelection(nextSelection);
      onSelectChange && onSelectChange({itemEvent: event, selected: nextSelection});
      return;
    }

    const {model, selected, only = false} = event;
    const nextSelection = {
      ...(only ? {} : selection),
      [getId(model)]: selected,
    };
    setSelection(nextSelection);
    onSelectChange && onSelectChange({itemEvent: event, selected: nextSelection});
  }, [selection, setSelection]);

  return {
    useSelect: true,
    selected: selection,
    onSelectItem,
  };
}

export interface InfiniteScrollUpdateQueryOptions {
  connectionPath: string,
  update?: (draft, fetchMoreResult) => void,
}

export function buildInfiniteScrollUpdateQuery({
  connectionPath,
  update,
}: InfiniteScrollUpdateQueryOptions) {
  return (previousResult, {fetchMoreResult}) => {
    const prevEdges = get(previousResult, `${connectionPath}.edges`, []);
    const nextEdges = get(fetchMoreResult, `${connectionPath}.edges`, []);

    if (!nextEdges.length) {
      return previousResult;
    }

    return produce(previousResult, draft => {
      if (update) {
        update(draft, fetchMoreResult);
      }

      set(draft, `${connectionPath}.edges`, [...prevEdges, ...nextEdges]);

      const pageInfo = get(fetchMoreResult, `${connectionPath}.pageInfo`);
      set(draft, `${connectionPath}.pageInfo`, pageInfo);
    });
  };
}
