import {ClickableListItem} from '@app-components/ClickableListItem';
import {
  ApplicationData,
  UserData,
} from '@app-lib/apollo/localTypes';
import {stopPropagation} from '@app-lib/components/events';
import {
  ClickableItemProps,
  getListItemMouseEvent,
  ItemWithSecondaryAction,
  MultiSelectableItemProps,
} from '@app-lib/components/lists';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  Avatar,
  Checkbox,
  IconButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import classNames from 'clsx';
import React, {useCallback} from 'react';


export interface UserItemProps extends ClickableItemProps<UserData>,
  ItemWithSecondaryAction<UserData>,
  MultiSelectableItemProps<UserData> {
  /** Application support is currently only for the Info panel createBy usage.
   * Does not support any callbacks with this type at this time. */
  user?: UserData | ApplicationData | null,
  className?: string,
  small?: boolean,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  noPadding: {
    padding: 0,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  name: {
    color: 'inherit',
  },
  email: {
    color: 'inherit',
  },
  smallRoot: {
    padding: `${theme.spacing(1) * 0.5}px ${theme.spacing(1)}px`,
  },
  smallWrapper: {
    minWidth: theme.spacing(3) * 1.5,
    marginTop: theme.spacing(1) * 0.5,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export function UserItem({
  user,
  useSelect = false,
  selected = false,
  className,
  small = false,
  onClick,
  onDoubleClick,
  onAuxClick,
  onContextMenu,
  onSelect,
  // get Typescript to not complain.
  secondaryActionIcon: SecondaryActionIcon,
  onClickSecondaryAction,
  itemRef,
}: UserItemProps) {
  const classes = useStyles();

  const handleClickSecondaryAction = useCallback((e) => {
    if (user && ('email' in user) && onClickSecondaryAction) {
      e.stopPropagation();
      onClickSecondaryAction(getListItemMouseEvent(e, user));
    }
  }, [user, onClickSecondaryAction]);

  const handleSelect = useCallback((e) => {
    user && ('email' in user) && onSelect && onSelect({model: user, selected: e.target.checked});
  }, [user, onSelect]);

  return (
    <ClickableListItem
      className={classNames(classes.root, className, {
        [classes.smallRoot]: small,
      })}
      itemRef={itemRef}
      button={!!onClick || !!onDoubleClick}
      alignItems="flex-start"
      model={user}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onAuxClick={onAuxClick}
      onContextMenu={onContextMenu}
    >
      {useSelect ? (
        <ListItemIcon onClick={stopPropagation}>
          <Checkbox
            edge="start"
            checked={selected}
            onChange={handleSelect}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
      ) : null}

      <ListItemAvatar
        className={classNames({
          [classes.smallWrapper]: small,
        })}
      >
        {user ? (
          <Avatar
            className={classNames(classes.avatar, {
              [classes.small]: small,
            })}
            src={('avatar' in user) && user.avatar ? user.avatar : undefined}
          />
        ) : (
          <Skeleton
            className={classes.avatar}
            variant="circle"
          />
        )}
      </ListItemAvatar>

      {user ? (
        <ListItemText
          primary={
            <Typography className={classes.name} noWrap>
              {user.name}
            </Typography>
          }
          secondary={small || !('email' in user) ? undefined : (
            <Typography className={classes.email} noWrap variant="body2">
              {user.email}
            </Typography>
          )}
        />
      ) : (
        <ListItemText
          primary={<Skeleton />}
          secondary={small ? undefined : <Skeleton />}
        />
      )}

      {SecondaryActionIcon && (
        <ListItemSecondaryAction>
          <IconButton color="inherit" onClick={handleClickSecondaryAction}>
            <SecondaryActionIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ClickableListItem>
  );
}
