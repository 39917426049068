import {OrganizationFragmentData} from '@app-features/organizations/types';
import {
  textOnlyQueryReducer,
  TextOnlyQueryVariables,
} from '@app-lib/components/pages/filters';
import {
  QueryVariablesAction,
  QueryVariablesState,
} from '@app-lib/components/pages/queries';


export type TenantSelectorModel =
  | OrganizationFragmentData

export type TenantSelectorAction =
  | QueryVariablesAction<TenantSelectorQueryVariables>

export type TenantSelectorState = QueryVariablesState<TenantSelectorQueryVariables>

export interface TenantSelectorQueryVariables extends TextOnlyQueryVariables {
}

export type TenantSelectorDispatch = (action: TenantSelectorAction) => void;

export const tenantSelectorQueryReducer = textOnlyQueryReducer;
