import {
  ConnectionOptions,
  initialMeta,
  Job,
  Monitor,
  MonitorStats,
  QueryJobsOptions,
  QueueMeta,
} from '@forks/swmq';
import {isEqual} from 'lodash';
import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  distinctUntilChanged,
  map,
} from 'rxjs/operators';


export interface UseMonitorState<TaskData = any, TaskResponse = any> {
  loading: boolean;
  jobs: Array<Job<TaskData, TaskResponse>>;
  error: any | null;
  stats: MonitorStats;
}

const initialStats: MonitorStats = {
  pending: 0,
  inProgress: 0,
  errors: 0,
  complete: 0,
  total: 0,
};

const initialState = {
  loading: true,
  jobs: [],
  error: null,
  stats: initialStats,
};

export function useMonitor<TaskData = any, TaskResponse = any>(
  name: string,
  connectionOpts?: ConnectionOptions,
): MutableRefObject<Monitor<TaskData, TaskResponse>> {
  return useRef<Monitor<TaskData, TaskResponse>>(initMonitor(name, connectionOpts));
}

export function useMonitorData<TaskData = any, TaskResponse = any>(
  name: string,
  monitorRef?: MutableRefObject<Monitor<TaskData, TaskResponse>>,
  connectionOpts?: ConnectionOptions,
  queryOpts?: QueryJobsOptions,
): UseMonitorState<TaskData, TaskResponse> {
  const ref = monitorRef || useMonitor<TaskData, TaskResponse>(name, connectionOpts);
  const [state, setState] = useState<UseMonitorState<TaskData, TaskResponse>>(initialState);

  useEffect(() => {
    const job$ = ref.current.jobWithStats$(queryOpts);
    const sub = job$.subscribe({
      next: jobs => setState(jobs),
      error: error => setState(prev => ({
        ...prev,
        loading: false,
        error,
      })),
    });
    return () => sub.unsubscribe();
  }, [queryOpts]);

  return state;
}

export function useMonitorStats<TaskData = any, TaskResponse = any>(
  name: string,
  monitorRef?: MutableRefObject<Monitor<TaskData, TaskResponse>>,
  connectionOpts?: ConnectionOptions,
  queryOpts?: QueryJobsOptions,
): MonitorStats {
  const ref = monitorRef || useMonitor<TaskData, TaskResponse>(name, connectionOpts);
  const [state, setState] = useState<MonitorStats>(initialStats);

  useEffect(() => {
    const job$ = ref.current.jobWithStats$(queryOpts);
    const sub = job$
      .pipe(
        map(jobs => jobs.stats),
        distinctUntilChanged(isEqual),
      )
      .subscribe(stats => setState(stats));
    return () => sub.unsubscribe();
  }, [queryOpts]);

  return state;
}

export function useMonitorMeta<TaskData = any, TaskResponse = any>(
  name: string,
  monitorRef?: MutableRefObject<Monitor<TaskData, TaskResponse>>,
  connectionOpts?: ConnectionOptions,
  queryOpts?: QueryJobsOptions,
): QueueMeta {
  const ref = monitorRef || useMonitor<TaskData, TaskResponse>(name, connectionOpts);
  const [state, setState] = useState<QueueMeta>(initialMeta);

  useEffect(() => {
    const meta$ = ref.current.meta$();
    const sub = meta$.subscribe(setState);
    return () => sub.unsubscribe();
  }, [queryOpts]);

  return state;
}

function initMonitor<TaskData, TaskResponse>(
  name: string,
  connectionOpts?: ConnectionOptions,
) {
  return new Monitor<TaskData, TaskResponse>(name, connectionOpts);
}
