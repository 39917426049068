import {downloadUrl} from '@app-features/file-tree/services/file-downloads';
import {
  FileUpload,
  UploadState,
} from '@app-lib/apollo/localTypes';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  isObject,
  isString,
} from 'lodash';
import Link from 'next/link';
import React, {
  Fragment,
  useCallback,
} from 'react';


export interface SecondaryItemTextProps {
  item: FileUpload,
  onRemove?: (e: any) => void,
}

const useStyles = makeStyles((theme: Theme) => {
  return ({
    links: {
      margin: theme.spacing(0.5),
    },
  });
});

export function SecondaryItemText({
  item,
  onRemove,
}: SecondaryItemTextProps) {
  const classes = useStyles();
  const handleDownload = useCallback(
    () => {
      if (item.file) {
        const url = URL.createObjectURL(item.file);
        downloadUrl(url, item.file.name);
      }
    },
    [item],
  );

  return item.error ? (
    <Fragment>
      {item.parentType === 'FileContainer' && item.file ? (
        <Typography color="textPrimary" noWrap>
          {`Original file name: ${item.file.name}`}
        </Typography>
      ) : null}
      <Typography color="error" noWrap>
        {!item.file ? 'File is missing' : renderError(item.error)}
      </Typography>
      <span className={classes.links}>
        <Link href="/folder/[folderid]" as={`/folder/${item.parentId}`}>
          Open Folder
        </Link>
      </span>
      {item.file ? (
        <Button
          className={classes.links}
          size="small"
          onClick={handleDownload}
        >
          Download File
        </Button>
      ) : null}
      {item.file ? (
        <Button
          className={classes.links}
          size="small"
          onClick={onRemove}
        >
          Cancel
        </Button>
      ) : null}
    </Fragment>
  ) : null;
}

function renderError(error: FileUpload["error"]) {
  if (isString(error)) {
    return error;
  } else if (isObject(error) && isString((error as any).message)) {
    return (error as any).message;
  } else {
    return 'Something went wrong';
  }
}
