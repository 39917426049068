import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  ListItemIcon,
  Paper,
  Typography,
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  Archive as ArchiveIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import {get} from 'lodash';
import React, {
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  NotificationList,
  NotificationListProps,
} from '../NotificationList';


export interface NotificationPanelProps {
  notifications?: NotificationListProps,
  onMarkAllRead?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
      height: 480,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      height: '75vh',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: 600,
    },
  },
  list: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(480px - 100px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(75vh - 100px)',
    },
  },
  header: {
    marginBottom: 5,
    paddingLeft: 8,
    paddingRight: 10,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 10,
    },
  },
  headerButton: {
    textAlign: 'right',
  },
  notificationText: {
    marginLeft: 10,
  },
  footer: {
    marginTop: 1,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export function NotificationPanel({
  notifications,
  onMarkAllRead,
  onClose,
}: NotificationPanelProps) {
  const classes = useStyles();

  const isLoading = !notifications || !notifications.list;
  const hasItems = !isLoading && get(notifications, 'list.edges.length', 0) > 0;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();
  const handleOpenMenu = useCallback((e) => setMenuOpen(true), [setMenuOpen]);
  const handleCloseMenu = useCallback((e) => setMenuOpen(false), [setMenuOpen]);

  return (
    <Paper className={classes.root} elevation={3}>
      {/* Header */}
      <Paper className={classes.header} elevation={3}>
        {/* Header Main Container */}
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justify="center"
        >
          {/* Header First Row */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={10}>
              <Typography className={classes.notificationText}>
                <b>Notifications</b>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {onClose ? (
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>

          {/* Header Second Row */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={2}>
              <Checkbox
                // TODO: impl behavior for this.
                checked={false}
                tabIndex={-1}
                disableRipple
              />
            </Grid>
            <Grid
              className={classes.headerButton}
              item
              xs={8}
            >
              {isLoading ? (
                <Skeleton />
              ) : (
                hasItems ? (
                  <Button onClick={onMarkAllRead}>
                    Mark all as read
                  </Button>
                ) : null
              )}
            </Grid>
            <Grid item xs={2}>
              <IconButton buttonRef={menuRef} onClick={handleOpenMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuRef.current}
                open={isMenuOpen}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <ListItemIcon>
                    <ArchiveIcon color="secondary" />
                  </ListItemIcon>
                  <Typography variant="inherit"> Archive </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu}>
                  <ListItemIcon>
                    <DeleteIcon color="secondary" />
                  </ListItemIcon>
                  <Typography variant="inherit"> Delete </Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* Notification List */}
      <Paper className={classes.list} square>
        <NotificationList
          {...(notifications || {})}
        />
      </Paper>
      {/* Footer */}
      <Paper className={classes.footer} elevation={3}>
        <Grid
          container
          direction="row"
          spacing={2}
        >
          <Grid item xs>
            {isLoading ? (
              <Skeleton />
            ) : (
              hasItems ? (
                <Button color="secondary">
                  <b>See All</b>
                </Button>
              ) : null
            )}
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
}
