import {
  Box,
  CircularProgress,
  Theme,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';


const useStyles = makeStyles((theme: Theme) => {
  return ({
    root: {
      top: '7px',
    },
    progress: {
      top: '7px',
      marginLeft: theme.spacing(1.5),
    },
    progressText: {
      fontSize: theme.spacing(1.2),
      lineHeight: '20px',
      textAlign: 'center',
    },
  });
});

export function CircularProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      position="relative"
      display="inline-flex"
    >
      <CircularProgress
        className={classes.progress} variant="static"
        {...props}
      />
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        ml={2}
      >
        <Typography
          noWrap
          className={classes.progressText}
        >
          {`${Math.round(
            props.value,
          )}`}
        </Typography>
      </Box>
    </Box>
  );
}
