import {INode} from '@app-lib/apollo/localTypes';
import {getListId} from '@app-lib/collections/utils';
import {
  ContextActionInclusion,
  ContextMenuActionConfig,
  ContextMenuActionSelectionMode,
  ContextMenuContext,
} from '@app-system/context-menus';
import {IndeterminateCheckBox as IndeterminateCheckBoxIcon} from '@material-ui/icons';
import {useCallback} from 'react';
import {
  ListItemMouseEvent,
  SelectedItems,
  SelectItemEvent,
} from './lists';
import {
  isMultiModeViewAction,
  MultiModeViewActions,
  multiModeViewReducer,
  MultiModeViewState,
} from './pages';


export type MultiSelectionActions<T> =
  | { type: "MULTI_SELECTION_ITEM_EVENT", payload: SelectItemEvent<T> }
  | { type: "MULTI_SELECTION_TOGGLE_ITEM_EVENT", payload: ListItemMouseEvent<T> }
  | { type: "MULTI_SELECTION_CLEAR" }
  | { type: "MULTI_SELECTION_RESET" }

export const ClearSelectionAction: ContextMenuActionConfig<"CLEAR_SELECTION", any, ContextMenuContext> = {
  type: 'CLEAR_SELECTION',
  icon: IndeterminateCheckBoxIcon,
  title: 'Clear Selection',
  selectionMode: ContextMenuActionSelectionMode.MultiselectOnly,
  dangerous: false,
  include: () => ContextActionInclusion.Include,
} as const;

export function handleClearSelectionAction(
  dispatch: (action: { type: "MULTI_SELECTION_CLEAR" }) => void
) {
  dispatch({type: "MULTI_SELECTION_CLEAR"});
}

export interface MultiSelectionState {
  multiSelect: boolean
  selection: SelectedItems
  size: number
}

export function getInitialMultiSelectionState() {
  return {
    multiSelect: false,
    selection: {},
    size: 0,
  };
}

export function isMultiSelectionAction<T>(action: { type: string }): action is MultiSelectionActions<T> {
  switch (action.type) {
    case 'MULTI_SELECTION_ITEM_EVENT':
    case 'MULTI_SELECTION_TOGGLE_ITEM_EVENT':
    case 'MULTI_SELECTION_CLEAR':
    case 'MULTI_SELECTION_RESET':
      return true;
    default:
      return false;
  }
}

export function isMultiModeViewAndSelectAction<T>(
  action: { type: string },
): action is MultiSelectionActions<T> | MultiModeViewActions {
  return isMultiSelectionAction(action) || isMultiModeViewAction(action);
}

export function multiSelectionReducer<T extends INode, S extends MultiSelectionState>(
  state: S,
  action: MultiSelectionActions<T>,
): S {
  switch (action.type) {
    case 'MULTI_SELECTION_ITEM_EVENT':
      if (action.payload === "RESET") {
        return {
          ...state,
          ...getInitialMultiSelectionState(),
        };
      } else {
        const {model, selected, only = false} = action.payload;
        const selection = {
          ...(only ? {} : state.selection),
          [getListId(model)]: selected,
        };
        return getNextState(state, selection, only);
      }

    case 'MULTI_SELECTION_TOGGLE_ITEM_EVENT':
      const {model} = action.payload;
      const key = getListId(model);
      const selection = {
        ...(state.selection),
        [key]: !state.selection[key],
      };
      return getNextState(state, selection, false);

    case 'MULTI_SELECTION_CLEAR': {
      return {
        ...state,
        ...getInitialMultiSelectionState(),
      };
    }

    default:
      return state;
  }
}

function getNextState<S extends MultiSelectionState>(state: S, selection: SelectedItems, only: boolean): S {
  const size = Object.values(selection).filter(it => it).length;
  const multiSelect = only
    ? false
    : size > 0;
  return {
    ...state,
    multiSelect,
    selection,
    size,
  };
}

export function multiModeViewAndSelectReducer<T extends INode, S extends MultiSelectionState & MultiModeViewState>(
  state: S,
  action: MultiModeViewActions | MultiSelectionActions<T>,
): S {
  if (action.type === 'SET_MULTI_SELECT') {
    const nextState = multiModeViewReducer(state, action);

    if (action.payload.active) {
      return nextState;
    } else {
      return multiSelectionReducer(nextState, {
        type: 'MULTI_SELECTION_RESET',
      });
    }
  } else if (isMultiModeViewAction(action)) {
    return multiModeViewReducer(state, action);
  } else if (isMultiSelectionAction(action)) {
    return multiSelectionReducer(state, action);
  } else {
    return state;
  }
}

export interface UseMultiSelectionReducer<T extends INode, Toggle extends boolean = false> {
  onSelectItem: (event: SelectItemEvent<T>) => void
  onClearSelection: () => void
  onToggleItem: Toggle extends true ? (event: ListItemMouseEvent<T>) => void : undefined
}

export function useMultiSelectionReducer<T extends INode, Toggle extends boolean = false>(
  dispatch,
): UseMultiSelectionReducer<T, Toggle>;
export function useMultiSelectionReducer<T extends INode, Toggle extends boolean>(
  dispatch,
  toggle: Toggle,
): UseMultiSelectionReducer<T, Toggle>;
export function useMultiSelectionReducer<T extends INode, Toggle extends boolean>(
  dispatch,
  toggle?: Toggle,
): UseMultiSelectionReducer<T, Toggle> {
  const onSelectItem = useCallback((event: SelectItemEvent<T>) => {
    dispatch({
      type: 'MULTI_SELECTION_ITEM_EVENT',
      payload: event,
    });
  }, [dispatch]);

  const onClearSelection = useCallback(
    () => dispatch({type: 'MULTI_SELECTION_CLEAR', payload: {}}),
    [],
  );

  if (toggle) {
    const onToggleItem = useCallback((event: ListItemMouseEvent<T>) => {
      dispatch({
        type: 'MULTI_SELECTION_TOGGLE_ITEM_EVENT',
        payload: event,
      });
    }, [dispatch]);

    return {
      onSelectItem,
      // @ts-ignore
      onToggleItem,
      onClearSelection,
    };
  }

  return {
    onSelectItem,
    // @ts-ignore
    onToggleItem: void 0,
    onClearSelection,
  };
}
