import {useTenantSelector} from '@app-features/organizations/components/TenantSelector/container';
import {OrganizationFragmentData} from '@app-features/organizations/types';
import {UserMenuProps} from '@app-features/users/components/UserMenu';
import {ListItemMouseEvent} from '@app-lib/components/lists';
import {logout} from '@app-system/auth/redux/actions';
import {setTenant} from '@app-system/tenant/redux/actions';
import {getTenantState} from '@app-system/tenant/redux/state';
import React, {
  useCallback,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';


export interface UseUserMenuHook {
  userMenu?: Pick<UserMenuProps,
    | "tenantSelector"
    | "tenantState"
    | "onSelectTenant"
    | "onLogout">,
  showUserMenu?: boolean,
  handleOpenUserMenu?: (e: React.MouseEvent<any> | ListItemMouseEvent<any>) => void,
  handleCloseUserMenu?: (e: React.MouseEvent<any> | ListItemMouseEvent<any>) => void,
}

export function useUserMenu(): UseUserMenuHook {
  const dispatch = useDispatch();
  const tenantState = useSelector(getTenantState);
  const [isOpen, setOpen] = useState(false);
  const handleOpenUserMenu = useCallback(() => setOpen(true), [setOpen]);
  const handleCloseUserMenu = useCallback(() => {
    setTimeout(() => setOpen(false));
  }, []);
  const tenantSelector = useTenantSelector();

  const onSelectTenant = useCallback(({model}: ListItemMouseEvent<OrganizationFragmentData>) => {
    dispatch(setTenant({tenant: model}));
    setOpen(false);
  }, [dispatch]);

  const onLogout = useCallback(() => {
    dispatch(logout());
    setOpen(false);
  }, [dispatch]);

  return {
    showUserMenu: isOpen,
    handleOpenUserMenu,
    handleCloseUserMenu,
    userMenu: {
      tenantSelector,
      tenantState,
      onSelectTenant,
      onLogout,
    },
  };
}
