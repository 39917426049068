import {
  has,
  toLower,
} from 'lodash';
import extensions from 'pretty-file-icons/index.json';
import fileExtension from 'file-extension';


const extended = {
  ...extensions,
  '.htm': 'html',
  '.mpga': 'mp3',
  '.m4a': 'mp4',
  '.mpe': 'video',
  '.m1v': 'video',
  '.mov': 'mp4',
  '.mpg4': 'mp4',
};

export function getFileIconPathForFile(fileName?: string) {
  const ext = fileExtension(fileName);
  return getFileIconPathForExtension(ext);
}

export function getFileIconPathForExtension(ext?: string) {
  const iconName = getFileIconName(ext);
  if (iconName === 'unknown') {
    return `/file-icons/unknown.svg`;
  }
  return `/file-icons/${extended[iconName]}.svg`;
}

export function getFileIconName(extension?: string) {
  if (!extension) {
    return 'unknown';
  }

  const base = toLower(extension);
  const normalized = '.' + base;

  if (has(extended, base)) {
    return base;

  } else if (has(extended, normalized)) {
    return normalized;

  } else if (base.length === 4) {
    const prefix = '.' + base.substring(0, 3);

    if (has(extended, prefix)) {
      return prefix;
    }
  }

  return 'unknown';
}
