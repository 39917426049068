import {
  FileUpload,
  UploadState,
} from '@app-lib/apollo/localTypes';
import {
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import {yellow} from '@material-ui/core/colors';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import {isNumber} from 'lodash';
import React, {Fragment} from 'react';
import {CircularProgressWithLabel} from './CircularProgressWithLabel';


export interface SecondaryActionTextProps {
  item: FileUpload,
  onRemove?: (e: React.MouseEvent<any>) => void,
  onRetryUpload?: (e: React.MouseEvent<any>) => void,
}

const useStyles = makeStyles((theme: Theme) => {
  return ({
    fileIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    errorText: {
      color: theme.palette.error.main,
    },
    completeText: {
      color: theme.palette.success.main,
    },
    pendingText: {
      color: yellow[600],
    },
    progress: {
      position: 'relative',
      top: '5px',
      marginLeft: theme.spacing(1.5),
    },
    links: {
      margin: theme.spacing(0.5),
    },
  });
});

export function SecondaryActionText({
  item,
  onRemove,
  onRetryUpload,
}: SecondaryActionTextProps) {
  const classes = useStyles();

  switch (item.status) {
    case UploadState.Pending:
      return (
        <LabeledAction
          labelClassName={classes.pendingText}
          label="Pending"
        >
          <IconButton>
            <HourglassEmptyIcon className={classes.pendingText} fontSize="small" />
          </IconButton>
          <IconButton onClick={onRemove}>
            <ClearIcon fontSize="small" color="error" />
          </IconButton>
        </LabeledAction>
      );
    case UploadState.Requesting:
      return (
        <LabeledAction
          labelClassName={classes.pendingText}
          label="Pending"
        >
          <IconButton>
            <HourglassEmptyIcon className={classes.pendingText} fontSize="small" />
          </IconButton>
        </LabeledAction>
      );
    case UploadState.Success:
      return (
        <LabeledAction
          labelClassName={classes.completeText}
          label="Complete"
        >
          <IconButton onClick={onRemove}>
            <CheckIcon className={classes.completeText} fontSize="small" />
          </IconButton>
        </LabeledAction>
      );
    case UploadState.Error:
      return (
        item.retryable ? (
          <LabeledAction
            labelClassName={classes.errorText}
            label="Retry Upload"
          >
            <IconButton onClick={onRetryUpload}>
              <RefreshIcon fontSize="small" color="error" />
            </IconButton>
          </LabeledAction>
        ) : (
          <LabeledAction
            labelClassName={classes.errorText}
            label="Failed"
          >
            <IconButton onClick={onRemove}>
              <ClearIcon fontSize="small" color="error" />
            </IconButton>
          </LabeledAction>
        ));
    case UploadState.Uploading:
      return (
        <LabeledAction
          labelClassName={classes.pendingText}
          label="In Progress"
        >
          {isNumber(item.percent) ? (
            <CircularProgressWithLabel
              size={20}
              value={item.percent}
            />
          ) : (
            <CircularProgress
              className={classes.progress}
              size={16}
            />
          )}
          <IconButton disabled>
            <ClearIcon fontSize="small" />
          </IconButton>
        </LabeledAction>
      );
    default:
      return (
        <LabeledAction
          labelClassName={classes.pendingText}
          label="Pending"
        >
          <IconButton>
            <HourglassEmptyIcon className={classes.pendingText} fontSize="small" />
          </IconButton>
          <IconButton onClick={onRemove}>
            <ClearIcon fontSize="small" color="error" />
          </IconButton>
        </LabeledAction>
      );
  }
}

function LabeledAction({
  labelClassName,
  label,
  children,
}) {
  return (
    <Fragment>
      <Typography variant="caption" className={labelClassName}>
        {label}
      </Typography>
      {children}
    </Fragment>
  );
}
