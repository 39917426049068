import {Settings} from '@app-features/settings/components/Settings';
import {useClientSettings} from '@app-features/settings/components/Settings/container';
import {MutationFormModalProps} from '@app-lib/forms';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';


export interface AppSettingsProps extends MutationFormModalProps {
}

export function AppSettings({
  onSuccess,
  onClose,
}: AppSettingsProps) {
  const clientProps = useClientSettings();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        App Settings
      </DialogTitle>

      <DialogContent>
        <Settings
          {...clientProps}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onSuccess}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
