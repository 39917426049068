import {
  createItemData,
  InfiniteScrollableList,
  makeRowComponent,
} from '@app-components/lists/InfiniteScrollableList';
import {LocalUserNotification} from '@app-lib/apollo/localTypes';
import {
  isItemSelected,
  ListItemsActionsProps,
  MultiSelectableListProps,
  RelayListProps,
  SecondaryActionList,
} from '@app-lib/components/lists';
import React from 'react';
import {NotificationItem} from '../NotificationItem';


export interface NotificationListProps extends RelayListProps<LocalUserNotification>,
  ListItemsActionsProps<LocalUserNotification>,
  SecondaryActionList<LocalUserNotification>,
  MultiSelectableListProps<LocalUserNotification> {
}

const RowComponent = makeRowComponent<any>(NotificationItem, {
  withProps: ({key, model, data}) => ({
    notification: model,
    selected: isItemSelected(true, key, data.selected),
    onSelect: data.onSelectItem,
    onClick: data.onClickItem,
    onDoubleClick: data.onDoubleClickItem,
    onContextMenu: data.onContextMenuItem,
    onClickSecondaryAction: data.onClickItemSecondaryAction,
  }),
});

export function NotificationList({
  list,
  onClickItemSecondaryAction,
  useSelect,
  selected,
  onSelectItem,
  onClickItem,
  onDoubleClickItem,
  onContextMenuItem,
  loadMoreItems,
}: NotificationListProps) {
  const itemData = createItemData(
    list,
    null,
    onClickItemSecondaryAction,
    useSelect,
    selected,
    onSelectItem,
    onClickItem,
    onDoubleClickItem,
    null,
    onContextMenuItem,
  );

  return (
    <InfiniteScrollableList
      itemSize={65}
      RowComponent={RowComponent}
      list={list}
      itemData={itemData}
      loadMoreItems={loadMoreItems}
    />
  );
}
