import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  PopperPlacementType,
  Theme,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {isFunction} from 'lodash';
import {ReferenceObject} from 'popper.js';
import React from 'react';


interface PortalPopperProps {
  open: boolean;
  placement?: PopperPlacementType;
  matchAnchorWidth?: boolean;
  anchorEl?: null | ReferenceObject;
  onClose: (event: React.MouseEvent<EventTarget>) => void;
  children?: React.ReactNode | ((props: { handleClose, open: boolean }) => React.ReactNode)
}

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: theme.zIndex.modal + 1,
  },
  dropdownBody: {
    minWidth: 250,
  },
}));

const popperOptions = {
  preventOverflow: true,
  boundariesElement: 'window',
};

export function PortalPopper({
  open,
  placement,
  matchAnchorWidth,
  anchorEl,
  onClose,
  children,
}: PortalPopperProps) {
  const classes = useStyles();
  return (
    <Popper
      className={classes.popper}
      open={open}
      anchorEl={anchorEl}
      transition
      placement={placement}
      popperOptions={popperOptions}
    >
      {({TransitionProps}) => (
        <Grow {...TransitionProps}>
          <Paper
            className={classes.dropdownBody}
            elevation={10}
            style={{
              width: matchAnchorWidth ? anchorEl?.clientWidth : void 0,
            }}
          >
            <ClickAwayListener onClickAway={onClose}>
              {isFunction(children) ? children({handleClose: onClose, open}) : children}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
