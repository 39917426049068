import {QueryResult} from '@apollo/client';
import {callRefetch} from '@app-lib/apollo/callRefetch';
import {
  isEqual,
  merge,
} from 'lodash';
import {
  MutableRefObject,
  useCallback,
} from 'react';
import {Observable} from 'rxjs';
import {useEventCallback} from 'rxjs-hooks';
import {
  debounceTime,
  map,
  withLatestFrom,
} from 'rxjs/operators';


export type QueryVariablesAction<Q extends {}> =
  | { type: "QUERY_VARIABLES_UPDATE", payload: { queryVariables: Partial<Q> } }

export interface QueryVariablesState<Q extends {}> {
  queryVariables: Q
  defaultFilter: boolean
}

export function getQueryVariablesInitialState<Q extends {}>(queryVariables: Q): QueryVariablesState<Q> {
  return {
    queryVariables,
    defaultFilter: true,
  };
}

export function isQueryVariablesAction<Q extends {}>(action: { type: string }): action is QueryVariablesAction<Q> {
  switch (action.type) {
    case 'QUERY_VARIABLES_UPDATE':
      return true;
    default:
      return false;
  }
}

function defaultMerge<Q extends {}>(prev: Q, next: Partial<Q>) {
  return merge({}, prev, next);
}

export function buildQueryVariablesReducer<Q extends {}>(
  isDefaultFilter: ((next: Q) => boolean) | null,
  mergeVars: (prev: Q, next: Partial<Q>) => Q = defaultMerge,
) {
  return (state: QueryVariablesState<Q>, action: QueryVariablesAction<Q>): QueryVariablesState<Q> => {
    switch (action.type) {
      case 'QUERY_VARIABLES_UPDATE':
        const next = mergeVars(state.queryVariables, action.payload.queryVariables);
        if (isEqual(state.queryVariables, next)) {
          return state;
        }
        return {
          queryVariables: next,
          defaultFilter: isDefaultFilter ? isDefaultFilter(next) : true,
        };
    }
  };
}

export function useFilterUpdate<Q extends {}>(
  dispatch,
) {
  return useCallback((queryVariables: Partial<Q>) => {
    dispatch({type: 'QUERY_VARIABLES_UPDATE', payload: {queryVariables}});
  }, []);
}

// TODO: finish typing and update usages
export function useLoadMoreItems<Q = any>(
  fetchMore,
  queryVariables: Q,
  cursor,
  // @ts-ignore - Not sure how to make TS happy here since default Q will have an after prop but not all.
  cursorName: keyof Q = 'after') {
  return useCallback(() => {
    if (fetchMore) {
      return fetchMore({
        variables: {
          ...queryVariables,
          [cursorName]: cursor,
        },
      }).then(() => undefined);
    }
  }, [fetchMore, queryVariables, cursor]);
}

export function onReloadList<TVariables>(
  refetch: MutableRefObject<QueryResult<any, TVariables>["refetch"] | undefined>,
  queryVariables: TVariables,
) {
  return useEventCallback(debounceChange, null, [refetch, queryVariables]);
}

type DebounceChangeInput<TVariables> = [
  MutableRefObject<QueryResult<any, TVariables>["refetch"] | undefined>,
  TVariables,
]

function debounceChange<TVariables>(
  event$: Observable<void>,
  _: Observable<null>,
  inputs$: Observable<DebounceChangeInput<TVariables>>,
) {
  return event$.pipe(
    debounceTime(10000),
    withLatestFrom(inputs$),
    map(([value, [refetch, queryVariables]]) => {
      callRefetch(refetch, queryVariables).catch(() => void 0);
    }),
  );
}
