import {gql} from '@apollo/client';
import {Organization} from '@app-lib/apollo/apiTypes';
import {ContextMenuContext} from '@app-system/context-menus';


export type CurrentTenantFragmentData = Pick<Organization,
  | "__typename"
  | "id"
  | "name"
  | "codeName"
  | "folderId"> & {
  hasPermissions: TenantPermissions;
}

export interface TenantPermissions {
  organization_data_update?: boolean | null;
  organization_members_read?: boolean | null;
  organization_members_manage?: boolean | null;
  team_data_create?: boolean | null;
  team_data_read?: boolean | null;
  team_data_update?: boolean | null;
  team_data_delete?: boolean | null;
  team_members_manage?: boolean | null;
  team_members_read?: boolean | null;
  project_data_create?: boolean | null;
  template_data_create?: boolean | null;
  template_data_read?: boolean | null;
  template_data_update?: boolean | null;
  template_data_delete?: boolean | null;
}

export const EmptyPermissions: TenantPermissions = {};

export interface TenantPermissionsContext extends ContextMenuContext {
  permissions: TenantPermissions;
}

export const CurrentTenantFragment = gql`
  fragment CurrentTenant on Organization {
    __typename
    id
    name
    codeName
    folderId

    hasPermissions {
      organization_data_update
      organization_members_read
      organization_members_manage
      team_data_create
      team_data_read
      team_data_update
      team_data_delete
      team_members_manage
      team_members_read
      project_data_create
      template_data_create
      template_data_read
      template_data_update
      template_data_delete
    }
  }
`;

export interface OrganizationFragmentData {
  __typename: "Organization"
  id: string
  name: string
  codeName?: string | null
  folderId: string
}

export const OrganizationFragment = gql`
  fragment OrganizationFragment on Organization {
    __typename
    id
    name
    codeName
    folderId
  }
`;
