import {SelectTimeZone} from '@app-components/FormControl/SelectTimeZone';
import {
  ITheme,
  Theme,
} from '@app-system/preferences/enums/Theme';
import {
  CameraResolution,
  Resolutions,
} from '@app-system/preferences/redux/reducer';
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Theme as MuiTheme,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import classNames from 'clsx';
import React, {
  Fragment,
  useCallback,
} from 'react';


export interface SettingsProps {
  enableCamera?: boolean;
  onChangeEnableCamera?: (e: { value: boolean }) => void;
  cameraResolution?: number;
  onChangeCameraResolution?: (e: { value: CameraResolution }) => void;
  useCameraAccept?: boolean;
  onChangeUseCameraAccept?: (e: { value: boolean }) => void;
  enableNotifications?: boolean;
  onChangeEnableNotifications?: (e: { value: boolean }) => void;
  dateFormat?: string;
  onChangeDateFormat?: (e: { value: string }) => void;
  useCurrentTimezone?: boolean;
  onChangeUseCurrentTimezone?: (e: { value: boolean }) => void;
  timezone?: string;
  onChangeTimezone?: (e: { value: string }) => void;
  theme?: ITheme["Values"];
  onChangeTheme?: (e: { value: ITheme["Values"] }) => void;
  cameraPermission?: PermissionState | 'error';
  locationPermission?: PermissionState | 'error';
}

export const useStyles = makeStyles((theme: MuiTheme) => ({
  root: {},
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    '&>:nth-child(2)': {
      marginLeft: 'auto',
    },
  },
  headerText: {
    fontSize: '1.25rem',
    color: theme.palette.type === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  primaryText: {
    fontSize: '1rem',
    color: 'primary',
    fontWeight: 500,
  },
  secondaryText: {
    fontSize: '0.75rem',
    color: 'inherit',
  },
  cameraSize: {
    minWidth: 120,
  },
  dateInput: {
    minWidth: '100%',
    margin: '0px',
  },
  dropDown: {
    margin: '0px',
  },
  settingsWithToggle: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  settingsWithDropdown: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  toggleSwitch: {
    marginRight: '88px',
  },
}));

export function Settings({
  enableCamera,
  onChangeEnableCamera,
  cameraResolution,
  onChangeCameraResolution,
  useCameraAccept,
  onChangeUseCameraAccept,
  enableNotifications,
  onChangeEnableNotifications,
  dateFormat,
  onChangeDateFormat,
  useCurrentTimezone,
  onChangeUseCurrentTimezone,
  timezone,
  onChangeTimezone,
  theme,
  onChangeTheme,
  cameraPermission,
  locationPermission,
}: SettingsProps) {
  const classes = useStyles();

  const handleChangeEnableCamera = useCallback(
    (e) => onChangeEnableCamera && onChangeEnableCamera({value: e.target.checked}),
    [onChangeEnableCamera],
  );

  const handleChangeCameraResolution = useCallback((e) => {
    const idx = e.target.value;
    if (Resolutions[idx] && onChangeCameraResolution) {
      onChangeCameraResolution({value: e.target.value});
    }
  }, [onChangeCameraResolution]);

  const handleChangeUseCameraAccept = useCallback(
    (e) => onChangeUseCameraAccept && onChangeUseCameraAccept({value: e.target.checked}),
    [onChangeUseCameraAccept],
  );

  const handleChangeEnableNotifications = useCallback(
    (e) => onChangeEnableNotifications && onChangeEnableNotifications({value: e.target.checked}),
    [onChangeEnableNotifications],
  );

  const handleChangeDateFormat = useCallback(
    (e) => onChangeDateFormat && onChangeDateFormat({value: e.target.value}),
    [onChangeDateFormat],
  );

  const handleChangeUseCurrentTimezone = useCallback(
    (e) => onChangeUseCurrentTimezone && onChangeUseCurrentTimezone({value: e.target.checked}),
    [onChangeUseCurrentTimezone],
  );

  const handleChangeTimezone = useCallback(
    (e) => onChangeTimezone && onChangeTimezone({value: e.target.value}),
    [onChangeTimezone],
  );

  const handleChangeTheme = useCallback(
    (e) => onChangeTheme && onChangeTheme({value: e.target.checked ? Theme.Dark : Theme.Light}),
    [onChangeTheme],
  );

  return (
    <Paper className={classes.root}>
      <Typography className={classes.headerText}>
        Camera Settings
      </Typography>
      <Box className={classNames(classes.boxContainer, classes.settingsWithToggle)}>
        <div>
          <Typography className={classes.primaryText}>
            Confirm Photos
          </Typography>
          <Typography className={classes.secondaryText}>
            Confirm or retake each photo in the camera.
          </Typography>
        </div>
        <Switch
          className={classes.toggleSwitch}
          color="primary"
          checked={useCameraAccept}
          onChange={handleChangeUseCameraAccept}
        />
      </Box>

      <Box className={classes.settingsWithDropdown}>
        <Typography className={classes.primaryText}>
          Camera Resolution
        </Typography>
        <Typography className={classes.secondaryText} gutterBottom>
          Select the resolution of photos taken.
          <br />
          Exact resolution may vary by camera aspect ratio.
        </Typography>
        <FormControl className={classes.cameraSize}>
          <Select
            className={classes.dropDown}
            value={cameraResolution}
            onChange={handleChangeCameraResolution}
          >
            <MenuItem value={0}>1024x576</MenuItem>
            <MenuItem value={1}>1280x720</MenuItem>
            <MenuItem value={2}>1920x1080</MenuItem>
            <MenuItem value={3}>2560x1440</MenuItem>
            <MenuItem value={4}>3840x2160</MenuItem>
            <MenuItem value={5}>4096x2160</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/*
      <Typography className={classes.headerText}>
        Notification Settings
      </Typography>
      <Box className={classes.settingsWithToggle}>
        <Box className={classes.boxContainer}>
          <Typography className={classes.primaryText}>
            Enable email notifications?
          </Typography>
          <Switch
            className={classes.toggleSwitch}
            color="primary"
            checked={enableNotifications}
            onChange={handleChangeEnableNotifications}
          />
        </Box>
      </Box>
       */}

      <Typography className={classes.headerText}>
        Date and Time Settings
      </Typography>
      <Box className={classes.settingsWithToggle}>
        <Box className={classes.boxContainer}>
          <Typography className={classes.primaryText}>
            Use Current Timezone?
          </Typography>
          <Switch
            className={classes.toggleSwitch}
            color="primary"
            checked={useCurrentTimezone}
            onChange={handleChangeUseCurrentTimezone}
          />
        </Box>
      </Box>
      <Box className={classes.settingsWithDropdown}>
        <Typography className={classes.primaryText}>
          Choose Timezone
        </Typography>
        <Typography className={classes.secondaryText}>
          Display times in a different timezone.
        </Typography>
        <SelectTimeZone
          className={classes.dateInput}
          selectClassName={classes.dropDown}
          disabled={useCurrentTimezone}
          value={timezone}
          onChange={handleChangeTimezone}
        />
      </Box>
      {/*
      <Box className={classes.settingsWithDropdown}>
        <Typography className={classes.primaryText}>
          Date Format
        </Typography>
        <Typography className={classes.secondaryText}>
          Select a different date format style
        </Typography>
        <FormControl className={classes.dateInput}>
          <NativeSelect
            className={classes.dropDown}
            value={dateFormat}
            onChange={handleChangeDateFormat}
          >
            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
            <option value="YYYY-MM-DD">YYYY-MM-DD</option>
          </NativeSelect>
        </FormControl>
      </Box>
      */}

      <Typography className={classes.headerText}>
        Theme Settings
      </Typography>
      <Box className={classes.boxContainer}>
        <Typography className={classes.primaryText}>
          Use Dark Theme
        </Typography>
        <Switch
          className={classes.toggleSwitch}
          color="primary"
          checked={theme === Theme.Dark}
          onChange={handleChangeTheme}
        />
      </Box>

      {typeof navigator !== "undefined" && "permissions" in navigator ? (
        <Fragment>
          <Typography className={classes.headerText}>
            Permissions
          </Typography>

          {cameraPermission === 'error' ? null : (
            <Box className={classes.settingsWithDropdown}>
              <Typography className={classes.primaryText}>
                Camera
              </Typography>
              <Typography className={classes.secondaryText} gutterBottom>
                This app uses the camera to allow you take photos and upload them.
              </Typography>
              {cameraPermission === 'denied' ? (
                <Typography className={classes.secondaryText}>
                  Camera permission has been revoked by the browser.
                  <br />
                  Please grant camera permission for this app in your browser to continue using the camera.
                </Typography>
              ) : cameraPermission === 'prompt' ? (
                <Typography className={classes.secondaryText}>
                  You will be prompted to allow access to the camera when you next try to take a photo.
                  <br />
                  Please allow permission when prompted in order to use the camera in this app.
                </Typography>
              ) : (
                <Typography className={classes.secondaryText}>
                  You have granted this app access to the camera.
                </Typography>
              )}
            </Box>
          )}

          {locationPermission === 'error' ? null : (
            <Box className={classes.settingsWithDropdown}>
              <Typography className={classes.primaryText}>
                Location
              </Typography>
              <Typography className={classes.secondaryText} gutterBottom>
                This app uses your location to save geolocation EXIF data to photos you take.
              </Typography>
              {locationPermission === 'denied' ? (
                <Typography className={classes.secondaryText}>
                  Location permission has been revoked by the browser.
                  <br />
                  Please grant location permission for this app in your browser or photos you take will not have
                  location
                  data saved in them.
                </Typography>
              ) : locationPermission === 'prompt' ? (
                <Typography className={classes.secondaryText}>
                  You will be prompted to allow access to the location when you next try to take a photo.
                </Typography>
              ) : (
                <Typography className={classes.secondaryText}>
                  You have granted this app access to the location.
                </Typography>
              )}
            </Box>
          )}
        </Fragment>
      ) : null}
    </Paper>
  );
}
