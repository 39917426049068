import {
  InputBase,
  InputBaseProps,
} from '@material-ui/core';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'clsx';
import {toLower} from 'lodash';
import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {Observable} from 'rxjs';
import {useEventCallback} from 'rxjs-hooks';
import {RestrictArray} from 'rxjs-hooks/dist/esm/type';
import {
  debounceTime,
  map,
  withLatestFrom,
} from 'rxjs/operators';


interface SearchInputProps extends Omit<InputBaseProps,
  | "className"
  | "classes"
  | "placeholder"
  | "value"
  | "onChange"> {
  className?: string,
  value?: string | null,
  onChange?: (e: SearchInputChangeEvent) => void,
}

export interface SearchInputChangeEvent {
  value: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.black, 0.15),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.black, 0.25),
    // },
    border: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export function SearchInput({
  className,
  value,
  onChange,
  ...inputProps
}: SearchInputProps) {
  const classes = useStyles();
  const input = useRef<HTMLInputElement>();
  const [handleChange] = useEventCallback(debounceChange, null, [onChange]);
  useEffect(
    () => {
      if (input.current) {
        input.current.value = value || '';
      }
    },
    [value],
  );

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        ref={input}
        defaultValue={value || ''}
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        {...inputProps}
        onChange={handleChange}
      />
    </div>
  );
}

function debounceChange(
  event$: Observable<React.ChangeEvent<HTMLInputElement>>,
  _: Observable<null>,
  inputs$: Observable<RestrictArray<[((e: SearchInputChangeEvent) => void) | undefined]>>,
) {
  return event$.pipe(
    map(event => event.currentTarget.value),
    debounceTime(500),
    withLatestFrom(inputs$),
    map(([value, [onChange]]) => {
      onChange && onChange({value});
      return;
    }),
  );
}

export function useTextFilterCallback<T extends {}>(name: keyof T, handler: ((e: T) => void) | undefined) {
  return useCallback(
    // @ts-ignore
    (e: SearchInputChangeEvent) => handler && handler({[name]: toLower(e.value) || null}),
    [handler],
  );
}
