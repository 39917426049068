import {ClickableListItem} from '@app-components/ClickableListItem';
import {LocalUserNotification} from '@app-lib/apollo/localTypes';
import {stopPropagation} from '@app-lib/components/events';
import {
  ClickableItemProps,
  getListItemMouseEvent,
  ItemWithSecondaryAction,
  MultiSelectableItemProps,
} from '@app-lib/components/lists';
import {
  Checkbox,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  MoreVert as MoreVertIcon,
  NotificationsNone as NotificationsNoneIcon,
} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import React, {useCallback} from 'react';


export interface NotificationItemProps extends ClickableItemProps<LocalUserNotification>,
  ItemWithSecondaryAction<LocalUserNotification>,
  MultiSelectableItemProps<LocalUserNotification> {
  notification?: LocalUserNotification | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export function NotificationItem({
  notification,
  selected = false,
  onClick,
  onDoubleClick,
  onContextMenu,
  onSelect,
  onClickSecondaryAction,
}: NotificationItemProps) {
  const classes = useStyles();

  const handleClickSecondaryAction = useCallback((e) => {
    if (notification && onClickSecondaryAction) {
      onClickSecondaryAction(getListItemMouseEvent(e, notification));
    }
  }, [notification, onClickSecondaryAction]);

  const handleSelect = useCallback((e) => {
    if (notification && onSelect) {
      onSelect({model: notification, selected: e.target.checked});
    }
  }, [notification, onSelect]);

  return (
    <ClickableListItem
      className={classes.root}
      button
      alignItems="flex-start"
      model={notification}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onContextMenu={onContextMenu}
    >
      <ListItemIcon onClick={stopPropagation}>
        <Checkbox
          edge="start"
          checked={selected}
          onChange={handleSelect}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <Hidden only={['sm', 'xs']}>
        <ListItemIcon>
          <NotificationsNoneIcon
            fontSize="large"
            color="secondary"
          />
        </ListItemIcon>
      </Hidden>

      {notification ? (
        <ListItemText
          primary={
            <Typography noWrap>
              {notification.title}
            </Typography>
          }
          secondary={
            <Typography noWrap variant="body2">
              {notification.message}
            </Typography>
          }
        />
      ) : (
        <ListItemText
          primary={<Skeleton />}
          secondary={<Skeleton />}
        />
      )}

      <ListItemSecondaryAction>
        <IconButton onClick={handleClickSecondaryAction}>
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ClickableListItem>
  );
}
