import {ClearSelectionHeaderProps} from '@app-system/context-menus/components/ClearSelectionMenuItem';
import {isString} from 'lodash';
import {NextComponentType} from 'next';
import {useRouter} from 'next/router';
import React from 'react';
import {ListItemMode} from '../../enums/ListItemMode';
import {SelectorChangeCallback} from '../lists';
import {QueryVariablesState} from './queries';


export interface QueryPageProps<T> {
  queryState: QueryVariablesState<T>
}

export interface FilterableHeaderProps<T> extends QueryPageProps<T> {
  onChangeFilter: (value: Partial<T>) => void
}

export interface MultiModeViewHeaderProps extends ClearSelectionHeaderProps {
  displayMode: ListItemMode,
  onChangeDisplayMode: SelectorChangeCallback<ListItemMode>,
}

export interface MultiModeGridHeaderProps {
  multiSelect: boolean,
  // This currently just matches the callback for MUI Switch.
  // Change if needed when implementation of the mode toggle is finalized.
  // Would prefer not to need a wrapper here if practical to avoid.
  onChangeMultiSelect: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
}

export interface DefaultInfoPanelViewHeaderProps {
  onShowInfo: (e: React.MouseEvent<any>) => void,
}

export function getPageIdFromQuery<PageProps extends {}, ID extends keyof PageProps>(
  props: PageProps,
  param: string,
  idName: keyof PageProps,
): string | null | undefined {
  if (typeof window !== 'undefined') {
    const router = useRouter();
    // @ts-ignore - TODO: figure this out
    return router?.query?.[param];
  } else {
    // @ts-ignore - TODO: figure out how to type this function properly
    return props[idName];
  }
}

export function idParamInitialPageProps<PageProps extends {}>(
  param: string,
  idName: keyof PageProps,
  sendTo: string,
  getInitialProps: (query, res, props: Partial<PageProps>) => PageProps | Promise<PageProps> = defaultGetInitialProps,
): NextComponentType["getInitialProps"] {
  return ({query, res}): PageProps | Promise<PageProps> => {
    if (typeof window === 'undefined') {
      const id = query[param];
      if (isString(id) && id) {
        return getInitialProps(query, res, {
          [idName]: id,
        } as unknown as Partial<PageProps>);
      } else if (res) {
        res.writeHead(302, {
          Location: sendTo,
        });
        res.end();
        return getInitialProps(query, res, {} as unknown as Partial<PageProps>);
      }
    }

    return getInitialProps(query, res, {} as unknown as Partial<PageProps>);
  };
}

function defaultGetInitialProps<PageProps>(query, res, props: Partial<PageProps>): PageProps {
  return props as PageProps;
}
