import {useLazyQuery} from '@apollo/client';
import {
  SearchInputChangeEvent,
  useTextFilterCallback,
} from '@app-components/SearchInput';
import {OrganizationListProps} from '@app-features/organizations/components/OrganizationList';
import {QueryError} from '@app-lib/apollo/utils';
import {useMultiSelectionReducer} from '@app-lib/components/multi-select';
import {
  defaultTextOnlyContentsFilter,
  TextOnlyContentsFilter,
} from '@app-lib/components/pages/filters';
import {
  getQueryVariablesInitialState,
  useFilterUpdate,
  useLoadMoreItems,
} from '@app-lib/components/pages/queries';
import React, {useReducer} from 'react';
import {
  organizations,
  OrganizationsDocumentNode,
} from './graphql';
import {
  tenantSelectorQueryReducer,
  TenantSelectorState,
} from './state';


export interface TenantSelectorProviderProps {
  folderId: string | null
  children: React.ReactNode
}

export interface TenantSelectorHook {
  organizations?: OrganizationListProps | null;
  error?: QueryError;
  searchText: string | null;
  onChangeSearchText: (e: SearchInputChangeEvent) => void;
  onLoadOrganizations: () => void;
}

const initialState: TenantSelectorState = getQueryVariablesInitialState({
  ...defaultTextOnlyContentsFilter,
  first: 50,
  after: null,
});

export function useTenantSelector(): TenantSelectorHook {
  const [state, dispatch] = useReducer(tenantSelectorQueryReducer, initialState);
  const [onLoadOrganizations, query] = useLazyQuery<OrganizationsDocumentNode, any>(organizations, {
    variables: state.queryVariables,
    ssr: false,
  });
  const {data, loading, error, called} = query;
  const cursor = data?.organizations?.pageInfo?.endCursor;

  const onChangeFilter = useFilterUpdate<TextOnlyContentsFilter>(dispatch);
  const onChangeSearchText = useTextFilterCallback('searchText', onChangeFilter);
  const loadMoreItems = useLoadMoreItems(query.fetchMore, state.queryVariables, cursor);

  const {onSelectItem} = useMultiSelectionReducer(dispatch);

  const hookProps: TenantSelectorHook = {
    searchText: state.queryVariables.searchText,
    onChangeSearchText,
    onLoadOrganizations: onLoadOrganizations,
  };

  if (error) {
    hookProps.error = {error};
    return hookProps;
  } else {
    hookProps.organizations = {
      list: !called || loading ? null : data?.organizations,
      loadMoreItems,
      onSelectItem,
    };
    return hookProps;
  }
}
