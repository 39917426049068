import {
  AppSettingsFormAction,
  AppSettingsFormState,
} from '@app-features/settings/forms/AppSettings/state';
import {
  CloseMutationFormAction,
  ClosesMutationFormState,
} from '@app-lib/forms';


export type MainLayoutFormsActions =
  | AppSettingsFormAction
  | CloseMutationFormAction

export type MainLayoutFormsState =
  | AppSettingsFormState
  | ClosesMutationFormState

export const initialState: MainLayoutFormsState = {
  open: null,
  props: null,
};

export function isFormsAction(action: { type: string }): action is MainLayoutFormsActions {
  switch (action.type) {
    case 'OPEN_APP_SETTINGS':
    case 'CLOSE_MUTATION_FORM':
      return true;
    default:
      return false;
  }
}

export function reducer(state: MainLayoutFormsState, action: MainLayoutFormsActions): MainLayoutFormsState {
  switch (action.type) {
    case 'OPEN_APP_SETTINGS':
      return {
        open: 'APP_SETTINGS',
        props: action.payload,
      };
    case 'CLOSE_MUTATION_FORM':
      return initialState;
  }
}
