import {
  clearComplete,
  hideComplete,
  hideUploadManager,
  removeFile,
  retryUpload,
} from '@app-features/file-tree/redux/upload-manager/actions';
import {
  FileUpload,
  UploadState,
} from '@app-lib/apollo/localTypes';
import {ListItemMouseEvent} from '@app-lib/components/lists';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  useFileUploadQueueMonitor,
  useFileUploadQueueMonitorMeta,
} from '../../services/FileUploadQueueMonitor';
import {FileUploadList} from '../FileUploadList';


export function FileUploadManager() {
  const state = useFileUploadQueueMonitor();
  const meta = useFileUploadQueueMonitorMeta();
  const dispatch = useDispatch();

  const handleClose = useCallback(() => dispatch(hideUploadManager()), [dispatch]);
  const handleRemoveCompleted = useCallback(() => dispatch(clearComplete()), [dispatch]);
  const handleRetryUpload = useCallback(
    (e: ListItemMouseEvent<FileUpload>) => dispatch(retryUpload(e.model)),
    [dispatch],
  );
  const handleRemoveFile = useCallback(
    (e: ListItemMouseEvent<FileUpload>) => {
      if (e.model.status === UploadState.Success) {
        dispatch(hideComplete(e.model));
      } else {
        dispatch(removeFile(e.model));
      }
    },
    [dispatch],
  );

  return state.showManager ? (
    <FileUploadList
      open={state.showManager}
      meta={meta}
      list={state.visible}
      stats={state.stats}
      onRemoveCompleted={handleRemoveCompleted}
      onRetryUpload={handleRetryUpload}
      onRemoveFile={handleRemoveFile}
      onClose={handleClose}
    />
  ) : null;
}
